import _ from 'lodash';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/Button';
import FormError from '../../../components/FormQuizz/FormError';
import FormField from '../../../components/FormQuizz/FormField';
import FormInput from '../../../components/FormQuizz/FormInput';
import Layout from '../../../components/Layout';
import axios from '../../../libs/axios';
import { ReactComponent as Chevron } from '../../../svgs/chevron-left.svg';
import { StyledLink, StyledPasswordReset } from './styled';

const PasswordRequest = () => {
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');
  const email = new URLSearchParams(search).get('email');

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({});
  const history = useHistory();
  const password = useRef({});
  password.current = watch('password', '');
  const onSubmit = (values) => {
    axios
      .post('/password-reset', { ...values, token, email })
      .then(() => {
        toast.success('Mot de passe modifié');
        history.push('/');
      })
      .catch((err) => {
        toast.error('Mots de passe incorrects');
        console.error(err);
      });
  };
  return (
    <Layout
      authPage
      handleSubmit={handleSubmit(onSubmit)}
      title="Modifier le mot de passe"
    >
      <StyledPasswordReset>
        <FormField id="password" label="Mot de passe" required>
          <FormInput
            register={register('password', {
              required: true,
            })}
            type="password"
          />
          <FormError error={_.get(errors, 'password')} />
        </FormField>

        <FormField id="passwordVerification" label="Vérification du mot de passe" required>
          <FormInput
            register={register('passwordVerification', {
              required: true,
              validate: (value) => value === password.current || 'Les mots de passe ne correspondent pas',
            })}
            type="password"
          />
          <FormError error={_.get(errors, 'passwordVerification')} />
        </FormField>
        <StyledLink>
          <Chevron />
          <Link
            to="/login"
          >
            Page de connection
          </Link>
        </StyledLink>
        <div
          className="login-submit"
        >
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Modifier
          </Button>
        </div>
      </StyledPasswordReset>
    </Layout>
  );
};

export default PasswordRequest;
