import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import store from './redux/store';
import './utils/normalize.css';

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer
      autoClose={3000}
      position="top-right"
      hideProgressBar={false}
    />
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
