/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FormError from '../FormQuizz/FormError';
import FormField from '../FormQuizz/FormField';
import FormInput from '../FormQuizz/FormInput';
import StyledFormSelect from '../FormQuizz/FormSelect';
import axios from '../../libs/axios';

const StyledForm = styled.div`
  display: flex;
  width: 100%;

  form {
    width: 100%;
    display: block;
    align-items: center;
    padding: 15px 20px;
  }
`;

const options = [
  { value: 'SuperAdmin', label: 'SuperAdmin' },
  { value: 'Admin', label: 'Admin' },
  { value: 'Editor', label: 'Editor' },
];

const FormUserEdit = ({
  register, errors, control, profil,
}) => {
  const { client, roles } = JSON.parse(localStorage.getItem('user'));
  const [optionsClient, setOptionsClient] = useState([]);

  useEffect(() => {
    if (roles === 'SuperAdmin') {
      const getClients = async () => {
        const resp = await axios.get('client');
        const tmpClients = [{
          _id: null,
          name: 'Aucun',
        }];
        resp?.data?.forEach((item) => {
          tmpClients.push(item);
        });
        setOptionsClient(_.cloneDeep(tmpClients));
      };
      getClients();
    }
  }, []);

  return (
    <StyledForm>
      <form>
        <FormField
          id="lastname"
          label="Nom"
        >
          <FormInput
            classname="secondary"
            register={register('lastname', {
              required: {
                value: true,
                message: 'Ce champs est requis',
              },
            })}
            type="text"
          />
          <FormError
            error={_.get(errors, 'lastname')}
          />
        </FormField>
        <FormField
          id="firstname"
          label="Prenom"
        >
          <FormInput
            classname="secondary"
            register={register('firstname', {
              required: {
                value: true,
                message: 'Ce champs est requis',
              },
            })}
            type="text"
          />
          <FormError
            error={_.get(errors, 'firstname')}
          />
        </FormField>
        <FormField
          id="email"
          label="E-mail"
        >
          <FormInput
            classname="secondary"
            register={register('email', {
              required: {
                value: true,
                message: 'Ce champs est requis',
              },
            })}
            type="email"
          />
          <FormError
            error={_.get(errors, 'email')}
          />
        </FormField>
        {
          profil ? '' : (
            <FormField
              id="roles"
              label="Role"
            >
              <StyledFormSelect
                name="roles"
                options={options}
                control={control}
                register={register('roles', {
                  required: {
                    value: true,
                    message: 'Ce champs est requis',
                  },
                })}
              />
              <FormError
                error={_.get(errors, 'roles')}
              />
            </FormField>

          )
        }

        {
          roles === 'SuperAdmin' ? (
            <FormField
              id="client"
              label="Client"
            >
              <StyledFormSelect
                name="client"
                options={optionsClient.map((c) => {
                  const { _id, name } = c;
                  return {
                    label: name,
                    value: _id,
                  };
                })}
                control={control}
                register={register('client', {})}
              />
              <FormError
                error={_.get(errors, 'client')}
              />
            </FormField>
          ) : null
        }
      </form>
    </StyledForm>
  );
};

FormUserEdit.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  profil: PropTypes.bool,
};

FormUserEdit.defaultProps = {
  profil: false,
};

export default FormUserEdit;
