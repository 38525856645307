/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import StyleFormRadio from './styled';

const StyledFormRadio = ({
  children, classname,
}) => (
  <StyleFormRadio
    classname={classname}
  >
    {children}
  </StyleFormRadio>
);

export default StyledFormRadio;
