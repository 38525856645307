import styled from 'styled-components';
import colors from '../../../utils/vars';

const StyledInput = styled.input`
    position: relative;
    padding: 15px 30px;
    margin-top: 8px;
    background-color: ${(p) => (p.classname === 'secondary' ? colors.white : colors.athens)};
    z-index: 1;
    color: ${colors.manatee};
    max-width: ${(p) => (p.type === 'number' ? '70px' : 'none')};
    border: ${colors.athens};
    border-radius: 5px;
`;

export default StyledInput;
