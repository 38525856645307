import { Promise } from 'bluebird';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Card from '../../components/Card';
import Layout from '../../components/Layout/index';
import axios from '../../libs/axios';
import { ReactComponent as Pen } from '../../svgs/pen.svg';
import { ReactComponent as Trash } from '../../svgs/trash.svg';

function User() {
  const [user, setUser] = useState();
  const [onRefreshDatas, setOnRefreshDatas] = useState(false);
  const [updateState, setUpdateState] = useState(0);

  const { _id, roles } = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const getUsersUrl = roles?.includes('SuperAdmin') ? '/users' : `/users/?clientId=${_id}`;
    const getUsers = async () => {
      const resp = await axios.get(getUsersUrl);
      setUser(_.get(resp, 'data', []));
    };
    getUsers();
  }, [onRefreshDatas]);

  const deleteUser = (userId, position) => {
    Promise.props({
      deleteSession: axios.delete(`/users/${userId}`),
    }).then(() => {
      const tmpUser = user;
      tmpUser.splice(position, 1);
      setUser(tmpUser);
      setUpdateState(updateState + 1);
      toast.success('supprimé avec succès !');
    });
  };
  useEffect(() => { }, [updateState]);

  return (
    <Layout
      onRefreshDatas={setOnRefreshDatas}
      title="Utilisateurs"
    >
      <Card>
        {user && user.length > 0 && user.map((item, itemIndex) => (item._id !== _id ? (
          <div key={item._id} className="item">
            <Link
              to={{
                pathname: `/user/${item._id}`,
              }}
              className="item__inner"
            >
              <span>{`${item?.roles} ${item?.roles !== 'SuperAdmin' ? `- ${item?.client?.name}` : ''}`}</span>
              <h3>{`${item?.firstname} ${item?.lastname}`}</h3>

            </Link>

            <Link to={`/user/${item._id}`}>
              <button type="button" className="item__btn edit">
                <Pen />
              </button>
            </Link>

            <button type="button" className="item__btn delete" onClick={() => window.confirm(`Supprimer l'utilisateur ${item.firstname} ${item.lastname}`) && deleteUser(item._id, itemIndex)}>
              <Trash />
            </button>
          </div>
        ) : null))}
      </Card>
    </Layout>
  );
}

export default User;
