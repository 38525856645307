import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';

const Context = createContext(null);

const ScenarioContext = ({ children }) => {
  const [sessionId, setSessionId] = useState(window.localStorage.getItem('session_id') || null);
  const [scenarioIndex, setScenarioIndex] = useState(window.localStorage.getItem('scenario_index') === 'null' ? null : parseInt(window.localStorage.getItem('scenario_index'), 10) || null);

  useEffect(() => window.localStorage.setItem('session_id', sessionId), [sessionId]);
  useEffect(() => window.localStorage.setItem('scenario_index', scenarioIndex), [scenarioIndex]);

  return (
    <Context.Provider value={{
      sessionId,
      setSessionId,
      scenarioIndex,
      setScenarioIndex,
    }}
    >
      {children}
    </Context.Provider>
  );
};

export const useScenario = () => useContext(Context);
export default ScenarioContext;

ScenarioContext.propTypes = {
  children: PropTypes.node.isRequired,
};
