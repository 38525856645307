import styled from 'styled-components';

const StyledQuizzForm = styled.div`
  display: flex;
  width: 100%;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    gap: 50px;
    
    .bg-wrapper {
      width: 200px;
      height: 200px;
      
      figure {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .color-pickers-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      button {
        display: flex;
        align-items: flex-end;
        padding-bottom: 20px;
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
`;

export default StyledQuizzForm;
