import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import QuestionsQuizzForm from '../../components/FormQuizz/QuestionsQuizzForm';
import Layout from '../../components/Layout/index';
import Spinner from '../../components/Spinner/Spinner';
import axios from '../../libs/axios';

const QuizzEdit = () => {
  const { id } = useParams();
  const [quizz, setQuizz] = useState(null);

  useEffect(() => {
    axios.get(`/quizz/${id}`)
      .then((datas) => {
        setQuizz(datas.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const {
    control, setValue, reset, register, handleSubmit, formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    axios.put(`/quizz/${id}`, values)
      .then(() => {
        toast.success('Enregistré avec succès !');
        setQuizz(values);
      })
      .catch(() => toast.error('Erreur lors de la mise à jour'));
  };

  useEffect(() => reset(quizz), [quizz]);

  return (
    <Layout
      title={quizz?.name}
      quizzId={quizz?._id}
      quizzPage
      handleSubmit={handleSubmit(onSubmit)}
    >
      { !quizz && <Spinner />}
      {
        quizz && (
          <QuestionsQuizzForm
            control={control}
            setValue={setValue}
            register={register}
            errors={errors}
          />
        )
      }
    </Layout>
  );
};

export default QuizzEdit;
