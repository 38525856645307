import PropTypes from 'prop-types';
import React from 'react';
import Header from '../Header/index';
import Sidebar from '../Sidebar/index';
import StyleLayout from './styled';

const Layout = ({
  children, subtitle, title, sessionUrl, quizzId, quizzPage,
  sessionDetail, handleSubmit, onRefreshDatas, authPage, sessionQrCode, sessionId, sessionFinished,
}) => (
  <StyleLayout>
    {
      authPage ? '' : (<Sidebar quizzId={quizzId} quizzPage={quizzPage} sessionDetail={sessionDetail} sessionQrCode={sessionQrCode} sessionId={sessionId} sessionFinished={sessionFinished} />)
    }

    <div className="layout-content">
      <Header
        onRefreshDatas={onRefreshDatas}
        subtitle={subtitle}
        title={title}
        sessionUrl={sessionUrl}
        handleSubmit={handleSubmit}
      />

      {children}
    </div>
  </StyleLayout>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  sessionUrl: PropTypes.string,
  quizzId: PropTypes.string.isRequired,
  quizzPage: PropTypes.bool,
  onRefreshDatas: PropTypes.func.isRequired,
  sessionDetail: PropTypes.bool,
  handleSubmit: PropTypes.func,
  authPage: PropTypes.bool,
  sessionQrCode: PropTypes.bool,
  sessionFinished: PropTypes.bool,
  sessionId: PropTypes.string,
};

Layout.defaultProps = {
  handleSubmit: () => { },
  subtitle: null,
  sessionUrl: null,
  sessionDetail: false,
  authPage: false,
  quizzPage: false,
  sessionQrCode: false,
  sessionFinished: false,
  sessionId: null,
};
export default Layout;
