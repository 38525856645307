import styled from 'styled-components';
import colors from '../../../utils/vars';

const StyleFormField = styled.li`
  display: block;
  /* height: auto; */
  border: ${(p) => (p.error ? `2px solid ${colors.blush}` : '2px solid transparent')};
  & + & {
    margin-top: 15px;
  }

  .header {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: ${colors.white};
    padding: 15px 20px;
    .drag {
      margin-right: 10px;
      position: relative;
      width: 30px;
      padding: 10px 0;
      cursor: grab;

      &::before, ::after {
        content: '';
        position: absolute;
        left: 0;
        width: 20px;
        height: 2px;
        background-color: ${colors.manatee};
        cursor: grab;
      }

      &::before {
        top: 5px;
      }

      &::after {
        bottom: 5px;
      }
    }

    .cta {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;

      button {
        svg {
          width: 15px;
          height: 15px;
          fill: ${colors.manatee};
        }
      }

      button + button {
        margin-left: 5px;
      }
    }
  }

  .content {
    transform: ${(p) => (p.open ? 'scaleY(1)' : 'scaleY(0)')};
    max-height: ${(p) => (p.open ? '10000px' : '0')};
    overflow: hidden;
    background-color: ${colors.white};
    transform-origin: top;
    transition: all .3s ease;
    padding: ${(p) => (p.open ? '10px 50px 30px' : 'none')};
  }
`;

export default StyleFormField;
