import { ObjectID } from 'bson';

const multiple = {
  question: '',
  responses: [],
  time: 30,
  type: 'multiple',
};

const picture = {
  question: '',
  mediaUrl: '',
  time: 30,
  type: 'picture',
};

const video = {
  question: '',
  mediaUrl: '',
  time: 30,
  type: 'video',
};

const onDragEnd = (result, move, insert) => {
  const { source, destination, draggableId } = result;
  if (!destination || destination.droppableId === 'aside-droppable') {
    return;
  }

  if (source.droppableId !== destination.droppableId) {
    if (draggableId === 'multiple') {
      insert(source.index, { ...multiple, _id: new ObjectID().toHexString() });
    } else if (draggableId === 'picture') {
      insert(source.index, { ...picture, _id: new ObjectID().toHexString() });
    } else if (draggableId === 'video') {
      insert(source.index, { ...video, _id: new ObjectID().toHexString() });
    } else {
      return;
    }
  }

  move(source.index, destination.index);
};

export default onDragEnd;
