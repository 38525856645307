import styled from 'styled-components';
import colors from '../../../../utils/vars';

const StyleSpecial = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    margin-top: 8px;
    align-items: center;
    top: ${(p) => `${p.top}px !important`};
    left: ${(p) => `${p.left}px !important`};
      label {
        display: block;
        position: relative;
        width: auto;
        height: 15px;
        cursor: pointer;
        z-index: 10;

        input {
            position: absolute;
            opacity: 0;
            visibility: visible;
            height: 0;
            width: 0;
        }

        [type="checkbox"]:checked + p {

            &::before, ::after {
              background-color: ${colors.white};
            }

            &::before {
              transform: rotateZ(40deg);
              top: 10px;
              width: 8px;
              left: 3px;
            }
            &::after {
              transform: rotateZ(-50deg);
              top: 7px;
              left: 7px;
            }
        }

        [type="checkbox"]:not(:checked) + p {
          &::before, ::after {
              border: 1px solid ${colors.blaze};  
              border-radius: 3px;
              top: 1px;
              left: 4px;
              width: 12px;
              height: 12px;
            }
        }

        p {
          position: absolute;
          height: 20px;
          top: 0;
          width: 20px;
          left: 5px;

            &::before, ::after {
              content: '';
              position: absolute;
              height: 2px;
              width: 15px;
            }
        }
    /* } */

    }

    .text {
      border: none;
      width: 100%;
      height: auto;
      padding: 15px 30px 15px 40px;
      background-color: ${colors.athens};
      z-index: 1;
      color: ${colors.manatee};
      border: ${colors.athens};
      border-radius: 5px;
    }

    .remove-item {
      position: absolute;
      fill: ${(p) => (p.check ? colors.white : colors.manatee)};
      padding: 10px;
      width: 10px;
      height: 10px;
      right: 5px;
      z-index: 5;
    }

  .active {
    background-color: ${colors.blaze};
    color: ${colors.white};
  }

  .drag {
    margin-right: 10px;
    position: relative;
    width: 30px;
    padding: 10px 0;
    cursor: grab;

    &::before, ::after {
      content: '';
      position: absolute;
      left: 0;
      width: 20px;
      height: 2px;
      background-color: ${colors.manatee};
    }

    &::before {
      top: 5px;
    }

    &::after {
      bottom: 5px;
    }
  }
`;

export default StyleSpecial;
