import styled from 'styled-components';
import colors from '../../utils/vars';

const StyledSessionDetail = styled.section`
  text-align: center;
  margin: auto 0;
  h2 {
    color: ${colors.thunder};
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  h4 {
    margin: 15px auto;
  }

  button {
    margin: 30px 0;
    padding: 12px 15px;
    border-radius: 4px;
    font-size: 1rem;

    & + button {
      margin-left: 40px;
    }
  }

  .participant-number {
    font-size: 5.5rem;
  }

  p {
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .session-finish {
    margin-bottom: 20px;
    span {
      font-size: 3rem;
      font-weight: 700;
    }
  }
  
  .meta-wrapper {
    padding: 20px 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    &.row {
      flex-direction: row-reverse;
      margin-bottom: 50px;
      justify-content: space-between;
    }

    &.row-reverse {
      flex-direction: row;
      margin-bottom: 50px;
      justify-content: space-between;
    }
    &_text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      span {
        font-size: 2.5rem;
        font-weight: 700;
      }
      
    }
     button, a {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 200px;
      padding: 20px 30px;
      transition: background-color .2s;
      background-color: ${colors.thunder};
      color: ${colors.white};
      font-family: 'Canaro', sans-serif;
      font-size: 1.1rem;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 5px;
      svg {
        width: 15px;
        height: 15px;
        fill: ${colors.white};
        transition: margin-right .3s ease-in-out;
      }
       
       &.manatee {
         background-color: ${colors.manatee};
       }
       
       &.blaze {
         background-color: ${colors.blaze};
       }
      
      &.rose {
        background-color: ${colors.rose};
      }
      
      &:hover {
        
        svg {
          margin-right: 10px;
          transition: margin-right .3s ease-in-out;
        }
        &.manatee {
          background-color: ${colors.manatee30};
        }
        
        &.blaze {
          background-color: ${colors.crusta};
        }
        
        &.rose {
          background-color: ${colors.blush};
        }
      }
    }
  }
  
  .free-steps-wrapper {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
`;

export default StyledSessionDetail;
