/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Button from '../Button';
import OnBoardQuestion from '../OnBoardQuestion';
import ResultTable from '../ResultTable';
import Spinner from '../Spinner/Spinner';
import StyledStepDetail from './styled';

const StepDetail = ({
  step = null, session = null, stats = {}, nextStep,
  resetSession, result = null, scenario = [], numberOfParticipants = 0, noResult = false,
  scenarioIndex = 0,
}) => (
  <StyledStepDetail>
    {
        (!step && !session) && (<h4>Error</h4>)
      }
    {step && (
    <>
      <Spinner type="barLoader" />
      <OnBoardQuestion
        stats={stats}
        step={step}
        scenario={scenario}
        numberOfParticipants={numberOfParticipants}
        scenarioIndex={scenarioIndex}
      />
    </>
    )}
    {result && !noResult && (
      <div>
        <ResultTable
          result={result}
          scenario={scenario}
        />
      </div>
    )}
  </StyledStepDetail>
);

export default StepDetail;
