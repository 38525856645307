import styled from 'styled-components';
import colors from '../../../utils/vars';

export const StyledLogin = styled.form`
  max-width: 600px;
  margin: 100px auto;
  background-color: ${colors.white};
  padding: 90px;

  .login-submit {
    display: flex;
  }

  button {
    margin: 20px auto;
  }
`;

export const StyledLink = styled.div`
  margin-top: 20px;
  text-align: end;


  a {
    margin-top: 30px;
    color: ${colors.manatee};
    transition: color .3s ease;
    &:hover {
      color: ${colors.emperor};
    }
  }
`;
