import _ from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/Button';
import FormError from '../../../components/FormQuizz/FormError';
import FormField from '../../../components/FormQuizz/FormField';
import FormInput from '../../../components/FormQuizz/FormInput';
import Layout from '../../../components/Layout';
import axios from '../../../libs/axios';
import { StyledLink, StyledLogin } from './style';

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const history = useHistory();
  const onSubmit = (values) => {
    axios
      .post('/login', values)
      .then((res) => {
        toast.success('Bienvenue sur SevaPlay');
        localStorage.setItem('user', JSON.stringify(res.data?.user));
        localStorage.setItem('access_token', JSON.stringify(res.data?.access_token));
        history.push('/');
      })
      .catch(() => {
        toast.error('Vos identifiants sont incorrects');
      });
  };
  return (
    <Layout
      authPage
      // handleSubmit={handleSubmit(onSubmit)}
      title="Bienvenue"
      subtitle="Connectez-vous pour créer et modifier vos quizs"
    >
      <StyledLogin>
        <FormField
          id="email"
          label="Email"
          required
          colorType
        >
          <FormInput
            register={register('email', {
              required: true,
            })}
            type="email"
          />
          <FormError error={_.get(errors, 'email')} />
        </FormField>
        <FormField id="password" label="Mot de passe" required>
          <FormInput
            register={register('password', {
              required: true,
            })}
            type="password"
          />
          <FormError error={_.get(errors, 'password')} />
        </FormField>

        <StyledLink>
          <Link
            to="/password-request"
          >
            Mot de passe oublié ?
          </Link>
        </StyledLink>

        <div
          className="login-submit"
        >
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Se connecter
          </Button>
        </div>
      </StyledLogin>
    </Layout>
  );
};

export default Login;
