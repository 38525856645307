import styled from 'styled-components';
import colors from '../../utils/vars';

const StyleSidebar = styled.aside`
  flex: 0 1 270px;
  background-color: ${colors.white};
  
  .sidebar-inner {
    display: flex;
    position: sticky;
    top: 0;
    flex-direction: column;
    height: 100vh;
  }
  
  .logo {
    padding: 22px 0;
    margin-bottom: 40px;
    border-bottom: 2px solid ${colors.manatee30};

    img {
      display: block;
      max-width: 70%;
      margin: 0 auto;
      transition: opacity .2s;
   
      &:hover {
        opacity: .8;
      }
    }
  }
  
  .back {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    transition: color .2s;
    color: ${colors.thunder};
    font-family: 'Canaro', sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    
    svg {
      margin-right: 8px;
      width: 11px;
      height: 11px;
      transition: margin-right .2s, fill .2s;
      fill: ${colors.thunder}
    }
    
    &:hover {
      color: ${colors.blaze};
      
      svg {
        margin-right: 12px;
        fill: ${colors.blaze};
      }
    }
  }
  
  .menu {
    li {
      & + li {
        margin-top: 10px;
      }
    }
    
    button {
      width: 100%;
    }
    a, button {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      padding: 12px 0;
      color: ${colors.manatee};
      transition: color .2s;
      font-family: 'Canaro', sans-serif;
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      
      svg {
        width: 25px;
        height: 25px;
        margin: 0 38px;
        path {
          fill: ${colors.thunder};
        }
      }
      
      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        height: 100%;
        transition: background-color .2s;
        background-color: ${colors.white};
      }
      
      &::before {
        left: 0;
        width: 73px;
      }
      
      &::after{
        right: 0;
        width: 5px;
      }
      
      &.is-active {
        color: ${colors.blaze};
        pointer-events: none;
        
        svg {
          path {
            fill: ${colors.white};
          }
        }

        &::before,
        &::after {
          background-color: ${colors.blaze};
        }
      }
      
      &:hover {
        color: ${colors.thunder};
        
        &::before,
        &::after {
          background-color: ${colors.manatee30};
        }
      }
    }
  }
  
  .utils {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    button, a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 25px 25px 18px;
      transition: background-color .2s;
      background-color: ${colors.thunder};
      color: ${colors.white};
      font-family: 'Canaro', sans-serif;
      font-size: 1.1rem;
      font-weight: 700;
      text-transform: uppercase;
      
      svg {
        width: 15px;
        height: 15px;
        fill: ${colors.white};
      }
      
      &.rose {
        background-color: ${colors.rose};
      }
      
      &:hover {
        background-color: ${colors.emperor};
        
        &.rose {
          background-color: ${colors.blush};
        }
      }
    }
  }
`;

export default StyleSidebar;
