/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ReactComponent as Cross } from '../../../../svgs/cross.svg';
import StyleSpecial from '../ResponseMultipleInput/styled';

const SpecialInput = ({
  register, index, name, response, provided, remove, indexResponseIsGood,
  setIndexResponseIsGood, setvalue, top, left,
}) => {
  const [check, setCheck] = useState(!!((indexResponseIsGood === index)));

  useEffect(() => {
    setCheck(!!((indexResponseIsGood === index)));
    setvalue(`${name}.responses.${index}.isGood`, !!((indexResponseIsGood === index)));
  }, [check, indexResponseIsGood]);

  return (
    <StyleSpecial
      top={top}
      left={left}
      ref={provided.innerRef}
      {...provided.draggableProps}
      check={check}
    >
      <div
        className="drag"
        {...provided.dragHandleProps}
      />
      <label
        className="check"
        htmlFor={response._id}
      >
        <input
          id={response._id}
          {...register(`${name}.responses.${index}.isGood`)}
          type="checkbox"
          defaultChecked={check}
          checked={!!check}
          onClick={() => {
            setIndexResponseIsGood(index);
          }}
        />
        <p className={`${check ? ' checkmark' : ''}`} />
      </label>
      <input
        className={`text ${check ? ' active' : ''}`}
        {...register(`${name}.responses.${index}.label`)}
        type="text"
        defaultValue={response.label}
      />
      <button
        className="remove-item"
        type="button"
        onClick={() => remove(index)}
      >
        <Cross />
      </button>
    </StyleSpecial>

  );
};
export default SpecialInput;
