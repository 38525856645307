import styled from 'styled-components';

export const StyledAsideElement = styled.figure`
  display: block;
  top: ${(p) => `${p.top}px !important`};
  left: 0 !important;
  width: 120px;
  height: 50px;
  min-width: 120px;
  padding: 10px 0;
  text-align: center;
  z-index: 1000;


  svg {
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
  }

  & + & {
    margin-top: 20px;
  }
`;

export const Clone = styled(StyledAsideElement)`
  + div {
    display: none !important;
  }
`;
