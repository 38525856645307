import styled from 'styled-components';
import colors from '../../utils/vars';

const StyleButton = styled.button`
  padding: 20px 30px;
  transition: background-color .2s;
  border-radius: 5px;
  background-color: ${colors.blaze};
  color: ${colors.white};
  font-family: 'Canaro', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  
  &:hover {
    background-color: ${colors.crusta};
  }
`;

export default StyleButton;
