/* eslint-disable react/prop-types */
import { map } from 'lodash';
import React, { cloneElement, isValidElement, useState } from 'react';
import FormField from './styled';

const StyledFormField = ({
  children, id, label, colorType, className, required, isArray, width,
}) => {
  const [completed, setCompleted] = useState(false);
  const handleInputValue = (value) => {
    setCompleted(value);
  };

  const childrenWithProps = map(children, (child, key) => {
    if (isValidElement(child)) {
      return cloneElement(child, { handleInputValue, key });
    }
    return child;
  });

  return (
    <FormField
      colorType={colorType}
      className={className}
      completed={completed}
      required={required}
      width={width}
    >
      { isArray ? children : childrenWithProps }
      {label && <label className="label" htmlFor={id}>{label}</label>}
    </FormField>
  );
};

export default StyledFormField;
