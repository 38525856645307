import PropTypes from 'prop-types';
import React from 'react';
import StyleButton from './styled';

const Button = ({ children, onClick, type }) => (
  <StyleButton type={type} onClick={onClick}>
    {children}
  </StyleButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
};

export default Button;
