/* eslint-disable react/prop-types */
import React from 'react';
import FormError from './styled';

const StyledFormError = ({ error }) => {
  let message = error?.message;
  if (!error) {
    return null;
  }

  if (!message) {
    if (error.type === 'required') {
      message = 'Ce champs est requis';
    }
  }
  return (
    <FormError>
      {message}
    </FormError>
  );
};

export default StyledFormError;
