import _, { unset } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ObjectID } from 'bson';
import Card from '../../components/Card';
import Layout from '../../components/Layout/index';
import axios from '../../libs/axios';
import { ReactComponent as Pen } from '../../svgs/pen.svg';
import { ReactComponent as Trash } from '../../svgs/trash.svg';
import { ReactComponent as Clone } from '../../svgs/clone.svg';

function Quizz() {
  const [quizz, setQuizz] = useState();
  const [updateState, setUpdateState] = useState(0);
  const history = useHistory();
  const { _id, roles } = JSON.parse(localStorage.getItem('user'));

  const getQuizz = async () => {
    setQuizz(null);
    const getQuizzsUrl = !_id && roles?.includes('SuperAdmin') ? '/quizz' : `/quizz/?userId=${_id}`;
    try {
      const resp = await axios.get(getQuizzsUrl);
      setQuizz(_.get(resp, 'data', []));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getQuizz();
  }, []);

  useEffect(() => { }, [updateState]);

  const deleteQuizz = (quizzId, position) => {
    axios.delete(`/quizz/${quizzId}`)
      .then(() => {
        const tmpQuizz = quizz;
        tmpQuizz.splice(position, 1);
        setQuizz(tmpQuizz);
        setUpdateState(updateState + 1);
        toast.success('supprimé avec succès !');
      }).catch((err) => console.error(err));
  };

  const cloneQuizz = (targetQuizz) => {
    const tmpClonedQuizz = _.cloneDeep(targetQuizz);
    const tmpScenario = [];
    tmpClonedQuizz?.scenario?.forEach((item) => {
      const tmpResponses = [];
      item.responses?.forEach((response) => {
        tmpResponses.push({ ...response, _id: new ObjectID().toHexString() });
      });
      tmpScenario.push({ ...item, responses: tmpResponses, _id: new ObjectID().toHexString() });
    });
    tmpClonedQuizz.scenario = tmpScenario;
    tmpClonedQuizz.client = tmpClonedQuizz?.client?._id;
    tmpClonedQuizz.name += ' - copie';
    unset(tmpClonedQuizz, '_id');
    unset(tmpClonedQuizz, '__v');
    unset(tmpClonedQuizz, 'sessions');
    axios.post('/quizz', { ...tmpClonedQuizz })
      .then((resp) => {
        toast.success('Clonée avec succès !');
        getQuizz();
        setUpdateState(updateState + 1);
      }).catch(() => toast.error('Erreur lors du clone'));
  };

  return (
    <Layout title="Quizzs">
      <Card>
        {quizz && quizz.length > 0 && quizz.map((item, itemIndex) => (
          <div key={item._id} className="item">
            <Link
              to={{
                pathname: `/quizz/${item._id}/sessions`,
              }}
              className="item__inner"
            >
              <span>{`Quiz ${itemIndex + 1}`}</span>
              {
                    roles?.includes('SuperAdmin') && (<span>{`Client ${item?.client?.name ? item?.client?.name : '-- Inconnu --'}`}</span>)
              }
              <h3>{item.name}</h3>
            </Link>

            <Link to={`/quizz/edit/${item._id}`}>
              <button type="button" className="item__btn edit">
                <Pen />
              </button>
            </Link>

            <button type="button" className="item__btn clone" onClick={() => window.confirm('Cloner ce quizz ?') && cloneQuizz(item)}>
              <Clone />
            </button>

            <button type="button" className="item__btn delete" onClick={() => window.confirm('Supprimer ce quizz ?') && deleteQuizz(item._id, itemIndex)}>
              <Trash />
            </button>
          </div>
        ))}
      </Card>
    </Layout>
  );
}

export default Quizz;
