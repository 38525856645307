import styled from 'styled-components';
import colors from '../../../utils/vars';

const StyledFieldCheckbox = styled.section`
  .add-item {
    display: block;
    margin-top: 20px;
    color: ${colors.blaze};
    font-family: Canaro;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: left;

    svg {
      margin-left: 25px;
      width: 10px;
      height: 10px;
      fill: ${colors.blaze};
    }
  }
`;

export default StyledFieldCheckbox;
