import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import StyleFormModal from './styled';
import { Modal } from '../Modal';
import SessionForm from '../SessionForm';
import FormUserCreate from '../FormUserCreate';
import FormClientCreate from '../FormClientCreate';

const FormModal = ({
  open, quizzId, setModalOpen, setReloadedDatas, formType, selectedItem,
}) => (open ? (
  <StyleFormModal>
    <Modal onClose={() => setModalOpen(false)}>
      {
                    formType === 'session' ? (
                      <SessionForm
                        quizzId={quizzId}
                        onClose={() => setModalOpen(false)}
                        setReloadedDatas={setReloadedDatas}
                        session={selectedItem}
                      />
                    ) : formType === 'user' ? (
                      <FormUserCreate
                        onClose={() => setModalOpen(false)}
                        setReloadedDatas={setReloadedDatas}
                      />
                    ) : (
                      <FormClientCreate
                        onClose={() => setModalOpen(false)}
                        setReloadedDatas={setReloadedDatas}
                      />
                    )
                }
    </Modal>
  </StyleFormModal>
) : null);

FormModal.propTypes = {
  open: PropTypes.node.isRequired,
  quizzId: PropTypes.node.isRequired,
  setModalOpen: PropTypes.node.isRequired,
  setReloadedDatas: PropTypes.node.isRequired,
  formType: PropTypes.node.isRequired,
  selectedItem: PropTypes.object,
};

FormModal.defaultProps = {
  selectedItem: null,
};

export default FormModal;
