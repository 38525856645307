import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ModalProvider } from './components/Modal';
import ScenarioContext from './context/Scenario';
import Routes from './Routes';
import GlobalStyle from './utils/globalStyle';

function App() {
  return (
    <div className="App" id="modalFix">
      <GlobalStyle />
      <Router>
        <ScenarioContext>
          <ModalProvider>
            <Switch>
              <Routes />
            </Switch>
          </ModalProvider>
        </ScenarioContext>
      </Router>
    </div>
  );
}

export default App;
