import PropTypes from 'prop-types';
import React from 'react';
import StyledUpload from './styled';

const Upload = ({
  label, register, accept, required, errors, name,
}) => (
  <StyledUpload>
    <label htmlFor="upload">{label}</label>
    <input
      type="file"
      id="upload"
      {...register(name, { required })}
    />

    {errors.file && required && <span>Ce champ est requis.</span>}
  </StyledUpload>
);

Upload.propTypes = {
  label: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  accept: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.string,
  errors: PropTypes.array,
};

Upload.defaultProps = {
  accept: false,
  required: false,
  errors: [],
};

export default Upload;
