import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from '../../libs/axios';
import FormError from '../FormQuizz/FormError';
import FormField from '../FormQuizz/FormField';
import FormInput from '../FormQuizz/FormInput';
import StyledSessionForm from './styled';
import FormRadio from '../FormQuizz/FormRadio';

const SessionForm = ({
  quizzId, onClose, setReloadedDatas, session,
}) => {
  const {
    handleSubmit, register, formState: { errors }, control,
  } = useForm({
    defaultValues: {
      name: session?.name || '',
      type: session?.type || 'live',
      groups: session?.groups || ['Default'],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'groups',
  });
  const onSubmit = (values) => {
    const tmpGroups = [];
    values?.groups?.forEach((group) => {
      tmpGroups?.push(group?.toUpperCase());
    });
    if (session) {
      axios.put(`/session/${session?._id}`, { ...values, groups: tmpGroups, quizzId })
        .then(() => {
          setReloadedDatas(true);
          onClose();
          toast.success('Session modifié avec succès !!');
          setTimeout(() => {
            setReloadedDatas(false);
          }, 500);
        })
        .catch((err) => console.error(err));
    } else {
      axios.post('/session', { ...values, groups: tmpGroups, quizzId })
        .then(() => {
          setReloadedDatas(true);
          onClose();
          toast.success('Créée avec succès !');
          setTimeout(() => {
            setReloadedDatas(false);
          }, 500);
        })
        .catch((err) => console.error(err));
    }
  };
  return (
    <StyledSessionForm onSubmit={handleSubmit(onSubmit)}>
      <FormField
        id="name"
        label="Nom de la session"
      >
        <FormInput
          register={register('name', {
            required: {
              value: true,
              message: 'Ce champs est requis',
            },
          })}
          type="text"
        />
        <FormError
          error={_.get(errors, 'name')}
        />
      </FormField>

      <FormField
        label="Type d'événement"
        required
      >
        <FormRadio
          classname="secondary"
        >
          <label
            htmlFor="sessionTypeLive"
          >
            En live (Animé par un administrateur)
            <input
              id="sessionTypeLive"
              {...register('type')}
              type="radio"
              value="live"
            />
            <span className="checkmark" />
          </label>
          <label
            htmlFor="sessionTypeFree"
          >
            Accès libre
            <input
              id="sessionTypeFree"
              {...register('type')}
              type="radio"
              value="free"
            />
            <span className="checkmark" />
          </label>
        </FormRadio>
        <FormError
          error={_.get(errors, 'type')}
        />
      </FormField>

      {fields.map((field, index) => (
        <div className="group-wrapper" id={field?.id}>
          <FormField
            id={`groups[${index}]`}
            label={`Nom du groupe : ${(index + 1)}`}
            isArray
          >
            <FormInput
              register={register(`groups[${index}]`)}
              type="text"
              control={control}
            />
          </FormField>
          <button type="button" onClick={() => remove(index)}>
            Suprrimer ce groupe
          </button>
        </div>

      ))}

      <div className="add-group-wrapper">
        <button type="button" onClick={() => append(' ')}>
          Ajouter un groupe
        </button>
      </div>

      <button
        type="submit"
        onClick={onSubmit}
      >
        {`${session ? 'Enregistrer' : 'Créer'}`}
      </button>
    </StyledSessionForm>
  );
};

SessionForm.propTypes = {
  quizzId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setReloadedDatas: PropTypes.func.isRequired,
  session: PropTypes.object,

};

SessionForm.defaultProps = {
  session: null,
};

export default SessionForm;
