import React from 'react';
import Layout from '../../components/Layout/index';

function Session() {
  return (
    <Layout>
      <h1>Session</h1>
    </Layout>
  );
}
export default Session;
