const initialState = {
  backUrl: process.env.REACT_APP_BACK_URL ? process.env.REACT_APP_BACK_URL : 'https://api.quizz.devel',
};

const constants = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default constants;
