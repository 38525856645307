import styled from 'styled-components';
import colors from '../../../utils/vars';

const StyledAsideForm = styled.aside`
  display: block;
  position: sticky;
  margin-right: 30px;
  padding: 0 20px 20px;
  top: 75px;
  left: 0;
  right: 0;
  bottom: 0;
  height: min-content;
  background-color: ${colors.white};
  transition: all .3s ease;

  button {
    position: absolute;
    top: -16px;
    display: flex;
    flex-direction: column;
    position: relative;
    color: ${colors.blaze};
    font-family: Canaro;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    align-items: center;

    figure {
      display: flex;
      margin-bottom: 10px;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      background-color: ${colors.blaze};
      border-radius: 90px;

      svg {
        position: absolute;
        width: 10px;
        height: 10px;
        fill: ${colors.white};
      }
    }
  }

  div + div {
    margin-top: 20px;
  }

  .droppable-aside {
    position: relative;
    max-height: ${(p) => (p.open ? '500px' : '0px')};
    transform: ${(p) => (p.open ? 'scaleY(1)' : 'scaleY(0)')};
    transform-origin: top;
    transition: all .3s ease;
  }
`;

export default StyledAsideForm;
