import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { ReactComponent as Check } from '../../../svgs/check.svg';
import { ReactComponent as CloudA } from '../../../svgs/cloud-a.svg';
import { ReactComponent as Graph } from '../../../svgs/graph.svg';
import { ReactComponent as Plus } from '../../../svgs/plus.svg';
import AsideElement from './AsideElement';
import StyledAsideForm from './styled';

const FormAside = ({ index }) => {
  const [open, setOpen] = useState(true);
  return (
    <StyledAsideForm
      open={open}
    >
      <button
        type="button"
        onClick={() => setOpen(!open)}
      >
        <figure>
          <Plus />
        </figure>
        Ajouter une question
      </button>
      <Droppable
        droppableId="aside-droppable"
      >
        {(provided) => (
          <div
            className="droppable-aside"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <AsideElement
              label="Choix"
              id="multiple"
              index={index}
              top={0}
            >
              <Check />
            </AsideElement>
            {/* <AsideElement */}
            {/*  label="Sondage" */}
            {/*  id="sondage" */}
            {/*  index={index} */}
            {/*  top={252} */}
            {/* > */}
            {/*  <Graph /> */}
            {/* </AsideElement> */}
            {/* <AsideElement */}
            {/*  label="Nuage de mots" */}
            {/*  id="cloud" */}
            {/*  index={index} */}
            {/*  top={336} */}
            {/* > */}
            {/*  <CloudA /> */}
            {/* </AsideElement> */}
            {provided.placeholder}
          </div>

        )}
      </Droppable>
    </StyledAsideForm>
  );
};

FormAside.propTypes = {
  index: PropTypes.number.isRequired,
};

export default FormAside;
