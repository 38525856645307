import { Promise } from 'bluebird';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Card from '../../components/Card';
import Layout from '../../components/Layout/index';
import axios from '../../libs/axios';
import { ReactComponent as LinkIcon } from '../../svgs/link.svg';
import { ReactComponent as Trash } from '../../svgs/trash.svg';

function Medias() {
  const [medias, setMedias] = useState();
  const [onRefreshDatas, setOnRefreshDatas] = useState(false);
  const [updateState, setUpdateState] = useState(0);
  const { id } = useParams();
  const location = useLocation();

  const { _id, roles } = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    Promise.props({
      getMedias: axios.get(`/upload${id ? (`/${id}`) : ''}`),
    }).then((res) => {
      setMedias(_.get(res, 'getMedias.data', []));
    }).catch((err) => {
      console.log(err);
    });
  }, [onRefreshDatas]);

  const deleteUpload = (targetId, position) => {
    Promise.props({
      deleteUpload: axios.delete(`/upload/${targetId}`),
    }).then(() => {
      const tmpMedias = medias;
      tmpMedias.splice(position, 1);
      setMedias(tmpMedias);
      setUpdateState(updateState + 1);
      toast.success('supprimé avec succès !');
    });
  };

  return (
    <Layout
      onRefreshDatas={setOnRefreshDatas}
      title="Médiathèque"
      quizzId={id || false}
      quizzPage={location.pathname.includes('quizz')}
    >
      <Card>
        {medias && medias.length > 0 && medias.map((item, itemIndex) => (item._id !== _id ? (
          <div key={item._id} className="item">
            {
              item?.type === 'image' && (
              <figure>
                <img src={item?.publicUrl} alt="#" />
              </figure>
              )
            }

            {
                item?.type === 'video' && (
                <div className="video-wrapper">
                  <video controls={false} src={item.publicUrl} width={200} />
                </div>
                )
            }

            <div className="item__title">
              <span>{item?.name}</span>
            </div>

            <button
              type="button"
              className="item__btn edit"
              onClick={() => {
                navigator.clipboard.writeText(item?.publicUrl);
                toast.success('Copié !');
              }}
            >
              <LinkIcon />
            </button>

            <button type="button" className="item__btn delete" onClick={() => window.confirm('Supprimer ce média ?') && deleteUpload(item._id, itemIndex)}>
              <Trash />
            </button>
          </div>
        ) : null))}
      </Card>
    </Layout>
  );
}

export default Medias;
