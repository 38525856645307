import PropTypes from 'prop-types';
import React from 'react';
import Input from './styled';

const StyledFormInput = ({
  register, type, defaultValue = null, classname,
}) => (
  <Input
    {...register}
    defaultValue={defaultValue}
    type={type}
    classname={classname}
  />
);

StyledFormInput.propTypes = {
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  type: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  classname: PropTypes.string,
};

StyledFormInput.defaultProps = {
  defaultValue: null,
  classname: null,
};

export default StyledFormInput;
