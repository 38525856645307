/* eslint-disable react/prop-types */
import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

const FormSelect = ({ control, options, name }) => (
  <Controller
    control={control}
    name={name}
    render={({
      field: { value, onChange, onBlur },
    }) => (
      <Select
        options={options}
        onChange={(_options) => {
          onChange(_options.value);
        }}
        onBlur={onBlur}
        value={options.filter((option) => value?.includes(option.value))}
        defaultValue={options.filter((option) => value?.includes(option.value))}
      />
    )}
  />
);

export default FormSelect;
