import styled from 'styled-components';
import colors from '../../../utils/vars';

export const StyledPasswordReset = styled.form`
 max-width: 600px;
  margin: 100px auto;
  background-color: ${colors.white};
  padding: 90px;

  .login-submit {
    display: flex;
  }

  button {
    margin: 20px auto;
  }
`;

export const StyledLink = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  transition: color .3s ease;

  svg {
    margin-right: 5px;
    width: 12px;
    height: 12px;
    fill: ${colors.manatee};
  }

  a {
    color: ${colors.manatee};
  }

  &:hover {
    a {
      color: ${colors.emperor};
    }
    svg {
      fill: ${colors.emperor};
    }
  }
`;
