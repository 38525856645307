import PropTypes from 'prop-types';
import React from 'react';
import StyledCard from './style';

const Card = ({ children }) => (
  <StyledCard>
    {children}
  </StyledCard>
);

export default Card;

Card.propTypes = {
  children: PropTypes.node.isRequired,
};
