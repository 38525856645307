import styled from 'styled-components';
import colors from '../../utils/vars';

const StyledSessionQrCode = styled.section`
  text-align: center;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  span {
    font-size: 3rem;
    font-weight: 700;
  }
  width: 100%;
  height: 100vh;
`;

export default StyledSessionQrCode;
