import FieldCheckbox from '../components/FormQuizz/FieldCheckbox';
import FieldMedia from '../components/FormQuizz/FieldMedia';

const switchFormField = (type) => {
  switch (type) {
    case 'multiple':
      return FieldCheckbox;
    case 'uniq':
      return FieldCheckbox;
    // case 'mixedWord':
    //   return FieldMixedWord;
    case 'picture':
      return FieldMedia;
    case 'video':
      return FieldMedia;
    default:
      return 'div';
  }
};

export default switchFormField;
