/* eslint-disable comma-dangle */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Redirect, Route, Switch
} from 'react-router-dom';
import { toast } from 'react-toastify';
import QRCode from 'qrcode.react';
import Login from './pages/auth/login';
import PasswordRequest from './pages/auth/passwordRequest';
import PasswordReset from './pages/auth/passwordReset';
/** Pages */
import Client from './pages/client/Client';
import ClientDetail from './pages/client/ClientDetail';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import Quiz from './pages/quiz/Quizz';
import QuizzConfiguration from './pages/quiz/QuizzConfiguration';
import QuizzDetail from './pages/quiz/QuizzDetail';
import QuizzQuestions from './pages/quiz/QuizzQuestions';
import Session from './pages/session/Session';
import SessionDetail from './pages/session/SessionDetail';
import User from './pages/user/User';
import UserDetail from './pages/user/UserDetail';
import SessionQrCode from './pages/session/SessionQrCode';
import Medias from './pages/medias/Medias';

const Connected = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const user = localStorage.getItem('user');
      const accessToken = localStorage.getItem('access_token');
      if (!user || !accessToken) {
        toast.error('Vous devez être connecté pour accèder à cette page');
      }
      return user && accessToken
        ? (
          <>
            <Component {...props} />
          </>
        )
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
    }}
  />
);

const NotConnected = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => <Component {...props} />}
  />
);
const Routes = () => (
  <Switch>
    <Connected path="/" exact component={Dashboard} />
    <Connected path="/quizz" exact component={Quiz} />
    <Connected path="/quizz/edit/:id" component={QuizzConfiguration} />
    <Connected path="/quizz/edit" component={QuizzConfiguration} />
    <Connected path="/quizz/:id/questions" component={QuizzQuestions} />
    <Connected path="/quizz/:id/sessions" component={QuizzDetail} />
    <Connected path="/session" exact component={Session} />
    <NotConnected path="/session/:id/QRCode" component={SessionQrCode} />
    <Connected path="/session/:id" component={SessionDetail} />
    <Connected path="/user" exact component={User} />
    <Connected path="/user/:id" component={UserDetail} />
    <Connected path="/me/:id" component={UserDetail} />
    <Connected path="/client" exact component={Client} />
    <Connected path="/client/:id" component={ClientDetail} />
    <Connected path="/quizz/medias/:id" component={Medias} />
    <Connected path="/medias" component={Medias} />
    <NotConnected path="/login" exact component={Login} />
    <NotConnected path="/password-request" exact component={PasswordRequest} />
    <NotConnected path="/password-reset" exact component={PasswordReset} />
    <Route component={NotFound} />
  </Switch>

);

export default Routes;
