import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import FormClient from '../../components/FormClientEdit';
import Layout from '../../components/Layout/index';
import axios from '../../libs/axios';
import Spinner from '../../components/Spinner/Spinner';

function ClientDetail() {
  const { id } = useParams();
  const [client, setClient] = useState(null);
  const [isLoading, setIsLoading] = useState();
  useEffect(() => {
    if (id) {
      axios.get(`/client/${id}`)
        .then((datas) => {
          setClient(datas.data);
        })
        .catch((err) => console.error(err));
    }
  }, []);

  const {
    control, setValue, reset, register, handleSubmit, formState: { errors }, watch,
  } = useForm({
    defaultValues: client,
  });

  const onSubmit = async (values) => {
    setIsLoading(true);
    const bodyData = { ...values };
    if (values?.logo && typeof values?.logo === 'object') {
      const newName = new Date().getTime() + values.logo[0].name;
      const formData = new FormData();
      formData.append('file', values.logo[0], newName);
      const postUploadResp = await axios.post('/upload', formData);
      _.set(bodyData, 'logo', postUploadResp?.data?.publicUrl);
    }
    if (id) {
      axios.put(`/client/${id}`, bodyData)
        .then((resp) => {
          toast.success('Client mis à jour');
          setClient(resp?.data);
          setIsLoading(false);
        })
        .catch(() => toast.error('Erreur lors de la mise à jour'));
    } else {
      axios.post('/client', bodyData)
        .then(() => {
          setClient(values);
          setIsLoading(false);
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => reset(client), [client]);
  return (
    <Layout
      title={client?.name}
      clientId={client?._id}
      quizzPage={false}
      handleSubmit={handleSubmit(onSubmit)}
    >
      {
          client?.logo?.length > 0 && !isLoading && (
          <div className="logo-wrapper">
            <label htmlFor="" className="label">Logo</label>
            <div className="logo">
              <figure>
                <img src={client?.logo} alt="logo" />
              </figure>
            </div>
          </div>
          )
      }
      {
        client && !isLoading ? (
          <FormClient
            control={control}
            setValue={setValue}
            register={register}
            errors={errors}
            watch={watch}
          />
        ) : (<Spinner type="barLoader" />)
      }
    </Layout>
  );
}

export default ClientDetail;
