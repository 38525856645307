import React from 'react';
import Layout from '../components/Layout/index';
import { ReactComponent as Cog } from '../svgs/cog.svg';
import { ReactComponent as Leave } from '../svgs/leave.svg';
import { StyledDashboard, StyledLink } from './styled';

function Dashboard() {
  const user = JSON.parse(localStorage.getItem('user'));
  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
  };

  return (
    <Layout title="Accueil">
      <StyledDashboard>
        <div className="banner">
          <h2>Bienvenue au</h2>
          <h2 className="important">Sevaplay</h2>
        </div>
      </StyledDashboard>
    </Layout>
  );
}

export default Dashboard;
