import styled from 'styled-components';
import colors from '../../utils/vars';

export const StyleOnBoard = styled.section`
  position: relative;
  padding: 30px 20px;
  background-color: ${colors.white};
  margin-top: 50px;
  border: solid ${colors.crusta};
  border-radius: 5px;
  h1 {
    margin-bottom: 50px;
    color: ${colors.thunder};
    text-transform: uppercase;
    font-family: 'Canaro',sans-serif;
    font-size: 2rem;
  }

  h2 {
    font-size: 2rem;
    text-align: left;
    margin: 40px 0;
  }

  .counter {
    position: absolute;
    top: 10px;
    right: 20px;
  }
`;

export const Responses = styled.div`
  max-width: 800px;
  margin: 0 auto;
  div + div {
    margin-top: 20px;
  }
`;

export const Response = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.thunder};
  font-weight: 700;
  text-transform: uppercase;
`;

export const ResponseLabel = styled.p`
  width: 30%;
  text-align: left;
`;

export const ProgressBar = styled.span`
  position: relative;
  width: 50%;
  max-width: 400px;
  height: 10px;
  border-radius: 15px;
  background-color: ${colors.manatee30};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    width: ${(p) => `${p.width}%`};
    background-color: ${colors.blaze};
    border-radius: 15px;
    transition: width 1s ease-in-out;
  }
`;

export const ResponseNumber = styled.div`
  display: flex;
  width: 10%;

  .response-number {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-left: 15px;

    svg {
      width: 15px;
      height: 15px;
      fill: ${colors.thunder};
    }
  }
`;
