import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import axios from '../../libs/axios';
import colors from '../../utils/vars';
import FormError from '../FormQuizz/FormError';
import FormField from '../FormQuizz/FormField';
import FormInput from '../FormQuizz/FormInput';
import Upload from '../Upload';

const StyledForm = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  button {
   &[type='submit'] {
    display: block;
    margin: 25px auto 0;
    padding: 10px;
    font-size: 1rem;
    text-align: center;
    color: ${colors.white};
    background-color: ${colors.blaze};
    border-radius: 5px;
    transition: background-color .2s;
    font-weight: 700;
    text-transform: uppercase;

    &:hover {
      background-color: ${colors.crusta};
    }
  }
  }
`;
const FormMediaCreate = ({ onClose, setReloadedDatas, quizzId }) => {
  const { handleSubmit, register, formState: { errors } } = useForm();
  const onSubmit = (values) => {
    const formData = new FormData();
    const newName = new Date().getTime() + values.file[0].name.trim().replace(/\s/g, '');
    formData.append('file', values.file[0], newName);
    formData.append('quizzId', quizzId);
    axios.post('/upload', formData)
      .then(() => {
        setReloadedDatas(true);
        onClose();
        toast.success('Créé avec succès !');
        setTimeout(() => {
          setReloadedDatas(false);
        }, 500);
      })
      .catch((err) => console.error(err));
  };
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      {/* <FormField */}
      {/*  id="name" */}
      {/*  label="Nom du client" */}
      {/* > */}
      {/*  <FormInput */}
      {/*    register={register('name', { */}
      {/*      required: { */}
      {/*        value: true, */}
      {/*        message: 'Ce champs est requis', */}
      {/*      }, */}
      {/*    })} */}
      {/*    type="text" */}
      {/*  /> */}
      {/*  <FormError */}
      {/*    error={_.get(errors, 'name')} */}
      {/*  /> */}
      {/* </FormField> */}

      <Upload label="Fichier" register={register} errors={errors} name="file" />

      <button
        type="submit"
        onClick={onSubmit}
      >
        Créer
      </button>
    </StyledForm>
  );
};

FormMediaCreate.propTypes = {
  onClose: PropTypes.func.isRequired,
  setReloadedDatas: PropTypes.func.isRequired,
  quizzId: PropTypes.func.isRequired,
};

export default FormMediaCreate;
