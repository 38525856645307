import styled from 'styled-components';
import colors from '../../../utils/vars';

const StyledInput = styled.div`
    display: flex;
    flex-direction: ${(p) => (p.classname === 'secondary' ? 'column' : 'row')};
    margin-top: 10px;
    
    label + label {
        margin-left: ${(p) => (p.classname === 'secondary' ? '0' : '20px')};
        margin-top: ${(p) => (p.classname === 'secondary' ? '10px' : '0')};
    }

    label {
        display: block;
        position: relative;
        padding-left: 25px;
        cursor: pointer;

        input {
            position: absolute;
            opacity: 0;
            visibility: hidden;
        }

        input:checked ~ span {
            background-color: ${colors.blaze};
        }

        input:not(:checked) ~ span {
            background-color: ${(p) => (p.classname === 'secondary' ? colors.athens : colors.athens)};
        }

        span {
            position: absolute;
            top: 0;
            left: 0;
            height: 15px;
            width: 15px;
            background-color: ${(p) => (p.classname === 'secondary' ? colors.white : colors.athens)};
            border-radius: 50%;
        }
    }
    
`;

export default StyledInput;
