/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import { ObjectID } from 'bson';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, useWatch } from 'react-hook-form';
import onDragEnd from '../../../libs/onDragEnd';
import { ReactComponent as Plus } from '../../../svgs/plus.svg';
import FormError from '../FormError';
import FormField from '../FormField';
import FormInput from '../FormInput';
import FormRadio from '../FormRadio';
import ResponseMultipleInput from './ResponseMultipleInput';
import ResponseUniqInput from './ResponseUniqInput';
import StyledFieldCheckbox from './styled';

const FieldCheckbox = ({
  question, time, type, errors, control, name, register, _id, setValue,
}) => {
  /** State */
  const [indexResponseIsGood, setIndexResponseIsGood] = useState(null);
  const [verifyIndexResponse, setVerifyIndexResponse] = useState(type === 'uniq');

  /** Watcher */
  const typeWatch = useWatch({
    control,
    name: `${name}.type`,
  });

  const responsesWatch = useWatch({
    control,
    name: `${name}.responses`,
  });

  /** Set form array */
  const {
    fields, remove, append, move,
  } = useFieldArray({
    control,
    name: `${name}.responses`,
  });

  /** Utils */
  useEffect(() => {
    /** Set default check for uniq type */
    if (type === 'uniq') {
      _.forEach(responsesWatch, (response, index) => (
        response.isGood ? setIndexResponseIsGood(index) : null));
      setVerifyIndexResponse(false);
    }
  }, []);

  const addNewResponse = {
    _id: new ObjectID().toHexString(),
    isGood: false,
    label: null,
  };

  return (
    <StyledFieldCheckbox>
      <FormField
        label="Type de question"
        required
      >
        <FormRadio>
          <label
            htmlFor={`${name}.multiple`}
          >
            Choix multiple
            <input
              id={`${name}.multiple`}
              {...register(`${name}.type`)}
              type="radio"
              value="multiple"
            />
            <span className="checkmark" />
          </label>
          <label
            htmlFor={`${name}.uniq`}
          >
            Choix unique
            <input
              id={`${name}.uniq`}
              {...register(`${name}.type`)}
              type="radio"
              value="uniq"
            />
            <span className="checkmark" />
          </label>
        </FormRadio>
        <FormError
          error={_.get(errors, `${name}.type`)}
        />
      </FormField>
      <FormField
        label="Chrono (seconde) : 99 ou plus pour le désactiver"
        required
      >
        <FormInput
          register={register(`${name}.time`)}
          type="number"
          defaultValue={time}
          control={control}
        />
        <FormError
          error={_.get(errors, `${name}.time`)}
        />
      </FormField>
      <FormField
        label="Intitulé de la question"
        required
      >
        <FormInput
          register={register(`${name}.question`, { required: true })}
          type="text"
          defaultValue={question}
          control={control}
        />
        <FormError
          error={_.get(errors, `${name}.question`)}
        />
      </FormField>
      <FormField
        label="Lien d'une image ou vidéo"
      >
        <FormInput
          register={register(`${name}.mediaUrl`)}
          type="text"
          defaultValue=""
        />
        <FormError
          error={_.get(errors, `${name}.mediaUrl`)}
        />
      </FormField>

      <FormField
        label="Cocher les/la bonne(s) réponse(s)"
        required
      >
        <button
          type="button"
          className="add-item"
          onClick={() => append(addNewResponse)}
        >
          Ajouter un élément
          <Plus />
        </button>
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, move)}
        >
          <Droppable
            droppableId={_id}
            type={`${name}.responses`}
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {
              fields.map((response, index) => (
                <Draggable
                  key={response._id}
                  draggableId={response._id}
                  index={index}
                  type={`${name}.responses`}
                >
                  {(provided2, snapshot2) => {
                    /** Need to handle drag and drop
                     * the component's height wihtout responses
                     * id 302 pixels
                     * And we add 54 pixel for each component's response
                    */
                    const top = snapshot2.isDragging ? (302 + index * 54) : 0;
                    const left = snapshot2.isDragging ? 50 : 0;

                    return (
                      typeWatch === 'multiple' ? (
                        <ResponseMultipleInput
                          type={type}
                          provided={provided2}
                          top={top}
                          left={left}
                          key={response._id}
                          name={name}
                          register={register}
                          index={index}
                          response={response}
                          remove={remove}
                        />
                      ) : (
                        !verifyIndexResponse ? (
                          <ResponseUniqInput
                            type={type}
                            fields={fields}
                            provided={provided2}
                            top={top}
                            left={left}
                            setIndexResponseIsGood={setIndexResponseIsGood}
                            indexResponseIsGood={indexResponseIsGood}
                            key={response._id}
                            setvalue={setValue}
                            name={name}
                            register={register}
                            index={index}
                            response={response}
                            remove={remove}
                          />
                        ) : (<div>in progress</div>)
                      )
                    );
                  }}
                </Draggable>
              ))
              }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <FormError
          error={errors}
        />
      </FormField>
    </StyledFieldCheckbox>
  );
};

FieldCheckbox.propTypes = {
  question: PropTypes.string.isRequired,
  responses: PropTypes.arrayOf({
    _id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isGood: PropTypes.bool.isRequired,
  }).isRequired,
  time: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  control: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
export default FieldCheckbox;
