const initialState = {
  getCurrentUser: () => {
    const apiKey = localStorage.getItem('apiKey');
    const expireRefresh = localStorage.getItem('expireRefresh');
    const refreshToken = localStorage.getItem('refreshToken');
    const user = localStorage.getItem('user');
    if (apiKey && expireRefresh && refreshToken && user) {
      return JSON.parse(user);
    }
    localStorage.removeItem('user');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('expireRefresh');
    localStorage.removeItem('refreshToken');
    return false;
  },
  clearCurrentUser: () => {
    localStorage.removeItem('user');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('expireRefresh');
    localStorage.removeItem('refreshToken');
  },
};

const user = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default user;
