import styled from 'styled-components';
import colors from '../../utils/vars';

const StyledExtractColor = styled.div`
    margin: 30px 0 ;
    width: 50%;
  
  img {
    width: 100px;
    height: 100px;
  }
  .colors-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    
    &_item {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      cursor: pointer;
      position: relative;
      
      &_detail {
        color: ${colors.white};
        padding: 2px 5px;
        border-radius: 5px;
        background: ${colors.black};
        font-weight: 700;
        position: absolute;
        bottom:-30px;
        right: -7px;
      }
      
      &:hover {
        opacity: .5;
        border: solid;
        &_detail {
          visibility: visible;
        }
      }
    }
  }
`;

export default StyledExtractColor;
