/* eslint-disable import/prefer-default-export */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { questionSlice } from './questions/slice';
import reducers from './reducers';

const rootReducer = combineReducers({
  [questionSlice.name]: questionSlice.reducer,
  constants: reducers,
});

// Set all reducers in combineReducer
const createStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {},
    }),
  });
  return { store };
};

const { store } = createStore();
export default store;
