/* eslint-disable react/forbid-prop-types */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import FormError from '../FormError';
import FormField from '../FormField';
import FormInput from '../FormInput';
import StyledFieldMedia from './styled';

const FieldMedia = ({
  question, time, type, errors, name, register, mediaUrl,
}) => (
  <StyledFieldMedia>
    <FormField
      label="Timer par défaut (seconde)"
      required
    >
      <FormInput
        register={register(`${name}.time`)}
        type="number"
        defaultValue={time}
      />
      <FormError
        error={_.get(errors, `${name}.time`)}
      />
    </FormField>
    <FormField
      label={`Intitulé de la ${type === 'video' ? 'video' : 'photo'}`}
      required
    >
      <FormInput
        register={register(`${name}.question`, { required: true })}
        type="text"
        defaultValue={question}
      />
      <FormError
        error={_.get(errors, `${name}.question`)}
      />
    </FormField>
    <FormField
      label={`Lien de la ${type === 'video' ? 'video' : 'photo'}`}
      required
    >
      <FormInput
        register={register(`${name}.mediaUrl`, { required: true })}
        type="text"
        defaultValue={mediaUrl}
      />
      <FormError
        error={_.get(errors, `${name}.mediaUrl`)}
      />
    </FormField>

  </StyledFieldMedia>
);

FieldMedia.propTypes = {
  question: PropTypes.string.isRequired,
  mediaUrl: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
};
export default FieldMedia;
