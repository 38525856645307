/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Promise from 'bluebird';
import _ from 'lodash';
import { toast } from 'react-toastify';
import store from '../redux/store';

const state = store.getState();

const instance = axios.create({
  baseURL: _.get(state, 'constants.constants.backUrl', 'https://api.quizzv2.sevatest.fr'),
  crossdomain: true,
});

// Request interceptor
instance.interceptors.request.use((config) => {
  if (localStorage.getItem('user') && localStorage.getItem('access_token')) {
    config.headers = { Authorization: `Bearer ${JSON.parse(localStorage.getItem('access_token'))}` };
  }

  return config;
}, (error) => Promise.reject(error));
// Add a response interceptor
instance.interceptors.response.use((response) => response,
  (error) => {
    if (error.response.status === 401) {
      toast.error('Votre session a expiré');
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      window.location.assign('/login');
    } else if (error.response.status === 403) {
      toast.error('Vous n\'êtes pas autorisé');
    }
    return Promise.reject(error);
  });
export default instance;
