import { Promise } from 'bluebird';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Card from '../../components/Card';
import Layout from '../../components/Layout/index';
import axios from '../../libs/axios';
import { ReactComponent as Pen } from '../../svgs/pen.svg';
import { ReactComponent as Trash } from '../../svgs/trash.svg';

function Client() {
  const [client, setClient] = useState();
  const [onRefreshDatas, setOnRefreshDatas] = useState(false);
  const [updateState, setUpdateState] = useState(0);

  useEffect(() => {
    Promise.props({
      getClient: axios.get('/client'),
    }).then((res) => {
      setClient(_.get(res, 'getClient.data', []));
    }).catch((err) => {
      console.log(err);
    });
  }, [onRefreshDatas]);

  const deleteClient = (clientId, position) => {
    Promise.props({
      deleteSession: axios.delete(`/client/${clientId}`),
    }).then(() => {
      const tmpClient = client;
      tmpClient.splice(position, 1);
      setClient(tmpClient);
      setUpdateState(updateState + 1);
      toast.success('supprimé avec succès !');
    });
  };
  useEffect(() => {}, [updateState]);

  return (
    <Layout
      onRefreshDatas={setOnRefreshDatas}
      title="Clients"
    >
      <Card>
        { client && client.length > 0 && client.map((item, itemIndex) => (
          <div key={item._id} className="item">
            <Link
              to={{
                pathname: `/client/${item._id}`,
              }}
              className="item__inner"
            >
              <span>{`Client ${itemIndex + 1}`}</span>

              <h3>{item.name}</h3>
            </Link>

            <Link to={`/client/${item._id}`}>
              <button type="button" className="item__btn edit">
                <Pen />
              </button>
            </Link>

            <button type="button" className="item__btn delete" onClick={() => window.confirm(`Supprimer le client "${item.name}"`) && deleteClient(item._id, itemIndex)}>
              <Trash />
            </button>
          </div>
        )) }
      </Card>
    </Layout>
  );
}

export default Client;
