import styled from 'styled-components';
import colors from '../../utils/vars';

export const StyleFinish = styled.div`
  display: flex;
  margin: 30px auto;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: ${colors.manatee};
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  background-color: ${colors.white};
  border-radius: 5px;

  padding: 50px 0;

  h2 {
    margin-bottom: 30px;
    color: ${colors.black};
    font-family: 'Canaro',sans-serif;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

export const StyleGlobalClassment = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding: 50px 0;

  .podium {
    display: flex;
    height: 300px;
    gap: 10px;
    .winner {
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 20px;

    
      &.first, &.second, &.third {
        .chair {
          background-color: ${colors.manatee30};
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 5px;
          span {
            font-size: 6rem;
            color: ${colors.blaze};
          }
        }
      }

      &.first {
        p {
          animation: bounce 2s infinite;

          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-30px);
            }
            60% {
              transform: translateY(-15px);
            }
          }
        }

        .chair {
          height: 200px;
          span {
            font-size: 10rem;
          }
        }
      }

      &.second {
        .chair {
          height: 150px;
        }
      }

      &.third {
        .chair {
          height: 100px;
        }
      }


    }
  }

  .onboard-part {
    width: 50%;
  }

  h3 {
    margin-bottom: 30px;
    font-family: 'Canaro', sans-serif;
    font-size: 2.5rem;
    font-style: italic;
    text-align: left;
  }

    button {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 200px;
      padding: 20px 30px !important;
      transition: background-color .2s;
      background-color: ${colors.thunder};
      color: ${colors.white};
      font-family: 'Canaro', sans-serif;
      font-size: 1.1rem;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 5px;
      svg {
        width: 15px;
        height: 15px;
        fill: ${colors.white};
        transition: margin-right .3s ease-in-out;
      }
       
       &.manatee {
         background-color: ${colors.manatee};
       }
       
       &.blaze {
         background-color: ${colors.blaze};
       }
      
      &.rose {
        background-color: ${colors.rose};
      }
      
      &:hover {
        
        svg {
          margin-right: 10px;
          transition: margin-right .3s ease-in-out;
        }
        &.manatee {
          background-color: ${colors.manatee30};
        }
        
        &.blaze {
          background-color: ${colors.crusta};
        }
      }
    }
`;

export const StyleLineOnBoard = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 15px;
  }
  div {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;

    .personal-onboard {
      color: ${colors.white};

      &::before {
        background-color: ${colors.crusta};
      }
    }
  }

`;

export const StylePosition = styled.span`
  margin-right: 30px;
  width: 40px;
  font-size: 1.6rem;
  color: ${colors.manatee};


  &::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: ${colors.manatee30};
  }
`;
export const StyleName = styled.p`
  font-size: 1.4rem;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-bottom: 5px;
    fill: ${colors.crusta};
  }
`;
export const StyleScore = styled.p`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${colors.emperor};
`;

export const WrapperOnBoard = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;
