import PropTypes from 'prop-types';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Back } from '../../svgs/chevron-left.svg';
import { ReactComponent as Cog } from '../../svgs/cog.svg';
import { ReactComponent as Download } from '../../svgs/download.svg';
import { ReactComponent as List } from '../../svgs/list.svg';
import { ReactComponent as User } from '../../svgs/user.svg';
import { ReactComponent as TurnOff } from '../../svgs/turnOff.svg';
import { ReactComponent as QuizzSVG } from '../../svgs/quizz.svg';
import { ReactComponent as Media } from '../../svgs/media.svg';
import { ReactComponent as Users } from '../../svgs/users.svg';
import { ReactComponent as Work } from '../../svgs/work.svg';
import Logo from '../../svgs/logo.svg';
import { ReactComponent as Question } from '../../svgs/question.svg';
import StyleSidebar from './styled';

const Sidebar = ({
  quizzId, quizzPage, sessionDetail, sessionQrCode, sessionId, sessionFinished,
}) => {
  const location = useLocation();
  const history = useHistory();

  const { roles, _id } = JSON.parse(localStorage.getItem('user'));

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    history.push('/login');
    toast.success('À bientôt !!');
  };

  return (
    <StyleSidebar>
      <div className="sidebar-inner">
        <figure className="logo">
          <Link
            to={{
              pathname: '/',
            }}
          >
            <img src={Logo} alt="" />
          </Link>
        </figure>

        {
          quizzPage && (
            <Link
              to={{
                pathname: '/quizz',
              }}
              className="back"
            >
              <Back />
              Retour à la liste des quiz
            </Link>
          )
        }

        <ul className="menu">
          {
            !sessionDetail && !quizzPage && !sessionQrCode && (
              <>
                {
                  ['SuperAdmin', 'Admin'].includes(roles) ? (
                    <li>
                      <Link
                        to={{
                          pathname: '/user',
                        }}
                        className={location.pathname === '/user' && 'is-active'}
                      >
                        <Users />
                        Utilisateurs
                      </Link>
                    </li>
                  ) : null
                }

                {
                  ['SuperAdmin'].includes(roles) ? (
                    <li>
                      <Link
                        to={{
                          pathname: '/client',
                        }}
                        className={location.pathname === '/client' && 'is-active'}
                      >
                        <Work />
                        Clients
                      </Link>
                    </li>
                  ) : null
                }

                {
                  ['SuperAdmin'].includes(roles) ? (
                    <li>
                      <Link
                        to={{
                          pathname: '/medias',
                        }}
                        className={location.pathname === '/medias' && 'is-active'}
                      >
                        <Media />
                        Médiathèque
                      </Link>
                    </li>
                  ) : null
                }

                <li>
                  <Link
                    to={{
                      pathname: '/quizz',
                    }}
                    className={location.pathname === '/quizz' && 'is-active'}
                  >
                    <QuizzSVG />
                    Quizzs
                  </Link>
                </li>

                <li>
                  <Link
                    to={{
                      pathname: `/me/${_id}`,
                    }}
                    className={location.pathname.includes('/me/') && 'is-active'}
                  >
                    <User />
                    Profile
                  </Link>
                </li>

                <li>
                  <button type="button" onClick={() => logout()}>
                    <TurnOff />
                    Déconnexion
                  </button>
                </li>
              </>
            )
          }

          {
            quizzPage && (
              <>
                <li>
                  <Link
                    to={{
                      pathname: `/quizz/${quizzId}/sessions`,
                    }}
                    className={location.pathname.includes('sessions') && 'is-active'}
                  >
                    <List />
                    Liste des sessions
                  </Link>
                </li>

                <li>
                  <Link
                    to={{
                      pathname: quizzId ? `/quizz/edit/${quizzId}` : '/quizz/edit',
                    }}
                    className={(location.pathname.match(/^\/quizz\/edit\/(([\d a-z]{23,23})|(undefined))/) || location.pathname === '/quizz/edit') && 'is-active'}
                  >
                    <Cog />
                    Configuration
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/quizz/medias/${quizzId}`,
                    }}
                    className={location.pathname.includes('/medias') && location.pathname.includes('quizz') && 'is-active'}
                  >
                    <Media />
                    Médiathèque
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/quizz/${quizzId}/questions`,
                    }}
                    className={location.pathname.includes('questions') && 'is-active'}
                  >
                    <Question />
                    Les questions
                  </Link>
                </li>
              </>
            )
          }
        </ul>

        {
          sessionDetail && (
            <>
              <Link
                to={{
                  pathname: `/quizz/${quizzId}/sessions`,
                }}
                className="back"
              >
                <Back />
                Retour à la liste des sessions
              </Link>

              <div className="utils">
                <a
                  href={`${process.env.REACT_APP_BACK_URL}/session/${sessionId}/download`}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={(e) => {
                    if (!sessionFinished) {
                      e.preventDefault();
                      toast.error("Cette session n'est pas encore terminée !");
                    }
                  }}
                >
                  Exporter le résultat
                  <Download />
                </a>
              </div>
            </>
          )
        }
      </div>
    </StyleSidebar>
  );
};

Sidebar.propTypes = {
  quizzId: PropTypes.string.isRequired,
  quizzPage: PropTypes.bool.isRequired,
  sessionDetail: PropTypes.bool.isRequired,
  sessionQrCode: PropTypes.bool.isRequired,
  sessionFinished: PropTypes.bool.isRequired,
  sessionId: PropTypes.string.isRequired,
};

export default Sidebar;
