/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { ReactComponent as ChevronRight } from '../../svgs/chevron-right.svg';
import { ReactComponent as ChevronDown } from '../../svgs/chevron-down.svg';
import {
  StyleFinish, StyleGlobalClassment,
  StyleLineOnBoard, StyleName, StylePosition, StyleScore, WrapperOnBoard,
} from './styled';
import { ReactComponent as Star } from '../../svgs/star.svg';
import colors from '../../utils/vars';

const ResultTable = ({ result }) => {
  const [showDetail, setShowDetail] = useState(false);
  const [classmentByGroup, setClassmentByGroup] = useState([]);
  const [classmentByUser, setClassmentByUser] = useState([]);

  useEffect(() => {
    if (result) {
      setClassmentByGroup(result?.classmentByGroup);
      setClassmentByUser(result?.classmentByUser);
    }
  }, [result]);

  return (
    <StyleFinish>
      <h2>Classement general</h2>
      {
        classmentByUser && classmentByGroup && (
        <StyleGlobalClassment>
          <div className="podium">
            <div className="winner second">
              <StyleName>{classmentByGroup[1]?.group}</StyleName>
              <div className="chair"><span>2</span></div>
            </div>
            <div className="winner first">
              <StyleName>
                {classmentByGroup[0]?.group}
                <Star />
              </StyleName>
              <div className="chair"><span>1</span></div>
            </div>
            <div className="winner third">
              <StyleName>{classmentByGroup[2]?.group}</StyleName>
              <div className="chair"><span>3</span></div>
            </div>
          </div>

          <div
            className="onboard-part"
          >
            <h3>Equipe</h3>
            <WrapperOnBoard>
              {
                      classmentByGroup.map((_group, index) => (
                        <StyleLineOnBoard>
                          <div>
                            <StylePosition>
                              {parseInt(index, 10) + 1}
                            </StylePosition>
                            <StyleName>
                              {_group?.group}
                              {!index ? <Star /> : ''}
                            </StyleName>
                          </div>
                          <StyleScore>
                            {parseFloat(_group?.average).toFixed(2)}
                            {' '}
                            POINTS
                          </StyleScore>
                        </StyleLineOnBoard>
                      ))
                    }
            </WrapperOnBoard>
          </div>

          <button type="button" className="manatee" onClick={() => setShowDetail(!showDetail)}>
            Plus de détails
            {
                    showDetail ? (<ChevronDown />) : (<ChevronRight />)
                  }
          </button>

          {
                    showDetail && (
                    <div
                      className="onboard-part"
                    >
                      <h3>Joueurs</h3>
                      <WrapperOnBoard>
                        {
                              classmentByUser.map((_user, index) => (
                                <StyleLineOnBoard>
                                  <div>
                                    <StylePosition>
                                      {parseInt(index, 10) + 1}
                                    </StylePosition>
                                    <StyleName>
                                      {_user?.firstName}
                                      {' '}
                                      {_user?.lastName}
                                      {!index ? <Star /> : ''}
                                    </StyleName>
                                  </div>
                                  <StyleScore>
                                    {parseFloat(_user?.average).toFixed(2)}
                                    {' '}
                                    POINTS
                                  </StyleScore>
                                </StyleLineOnBoard>
                              ))
                            }
                      </WrapperOnBoard>
                    </div>
                    )
                }

        </StyleGlobalClassment>

        )
      }
    </StyleFinish>
  );
};

export default ResultTable;
