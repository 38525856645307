/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/no-children-prop */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
 Link, useLocation, useParams, useHistory,
} from 'react-router-dom';
import Button from '../Button/index';
import FormClientCreate from '../FormClientCreate';
import FormUserCreate from '../FormUserCreate';
import { Modal } from '../Modal';
import SessionForm from '../SessionForm';
import StyleHeader from './styled';
import FormMediaCreate from '../FormMediaCreate';

const Header = ({
  subtitle, title, sessionUrl, handleSubmit, onRefreshDatas,
}) => {
  const location = useLocation();
  const params = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [formType, setFormType] = useState(null);
  const [reloadedDatas, setReloadedDatas] = useState(false);
  const history = useHistory();

  useEffect(() => {
    onRefreshDatas(reloadedDatas);
  }, [reloadedDatas]);

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    history.push('/login');
  };

  return (
    <StyleHeader>
      <div>
        <span>{subtitle}</span>
        <h2 className="title">{title || 'Admin quizz'}</h2>
      </div>

      {
        location.pathname === '/user'
        && (
          <Button onClick={() => {
            setModalOpen(!modalOpen);
            setFormType('user');
          }}
          >
            Créer un nouvel utilisateur
          </Button>
        )
      }

      {
        location.pathname.includes('/medias')
        && (
          <Button onClick={() => {
            setModalOpen(!modalOpen);
            setFormType('media');
          }}
          >
            Ajouter un fichier
          </Button>
        )
      }

      {
        location.pathname.match(/^\/user\/(\d+)/)
        && (
          <Button
            type="submit"
            onClick={handleSubmit}
          >
            Mettre à jour l&apos;utilisateur
          </Button>
        )
      }

      {
        location.pathname.match(/^\/me\/(\d+)/)
        && (
          <Button
            type="submit"
            onClick={handleSubmit}
          >
            Mettre à jour le profil
          </Button>
        )
      }

      {
        location.pathname === '/client'
        && (
          <Button onClick={() => {
            setModalOpen(!modalOpen);
            setFormType('client');
          }}
          >
            Créer un nouveau client
          </Button>
        )
      }

      {
        location.pathname.match(/^\/client\/(\d+)/)
        && (
          <Button
            type="submit"
            onClick={handleSubmit}
          >
            Mettre à jour le client
          </Button>
        )
      }

      {
        location.pathname === '/quizz'
        && (
          <Link className="btn" to="/quizz/edit">
            Créer un nouveau quizz
          </Link>
        )
      }

      {
        location.pathname.includes('sessions')
        && (
          <Button onClick={() => {
            setModalOpen(!modalOpen);
            setFormType('session');
          }}
          >
            Créer une session
          </Button>
        )
      }

      {
          location.pathname === '/'
          && (
              <Button onClick={() => logout()}>
                Déconnexion
              </Button>
          )
      }

      {
        sessionUrl
        && <a className="btn" href={sessionUrl} target="_blank" rel="noopener noreferrer nofollow">Accéder au quizz</a>
      }

      {
        location.pathname.match(/^\/quizz\/edit\/(\d+)/)
        && (
          <Button
            type="submit"
            onClick={handleSubmit}
          >
            Mettre à jour la configuration
          </Button>
        )
      }

      {
        location.pathname === '/quizz/edit'
        && (
          <Button
            type="submit"
            onClick={handleSubmit}
          >
            Creer mon quizz
          </Button>
        )
      }

      {
        location.pathname.includes('questions')
        && (
          <Button
            type="submit"
            onClick={handleSubmit}
          >
            Mettre à jour les questions
          </Button>
        )
      }
      { modalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          {
            formType === 'session' ? (
              <SessionForm
                quizzId={params.id}
                onClose={() => setModalOpen(false)}
                setReloadedDatas={setReloadedDatas}
              />
            ) : formType === 'user' ? (
              <FormUserCreate
                onClose={() => setModalOpen(false)}
                setReloadedDatas={setReloadedDatas}
              />
            ) : formType === 'media' ? (
                <FormMediaCreate
                  quizzId={params.id}
                  onClose={() => setModalOpen(false)}
                  setReloadedDatas={setReloadedDatas}
                />
            ) : (
                  <FormClientCreate
                    onClose={() => setModalOpen(false)}
                    setReloadedDatas={setReloadedDatas}
                  />
                )
          }
        </Modal>
      )}
    </StyleHeader>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  sessionUrl: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onRefreshDatas: PropTypes.func,
};

Header.defaultProps = {
  subtitle: null,
  sessionUrl: null,
  onRefreshDatas: () => { },
};

export default Header;
