import styled from 'styled-components';

const StyledQuizzForm = styled.div`
    display: flex;
    width: 100%;

  form {
    width: 100%;
    min-height: 50vh;
    .wrapper {
      height: 100%;
    }
  }
`;

export default StyledQuizzForm;
