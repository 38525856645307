import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ColorExtractor } from 'react-color-extractor';
import StyledExtractColor from './styled';

const ExtractColor = ({ img, setValue }) => {
  const [colors, setColors] = useState();
  const [url, setUrl] = useState();
  useEffect(() => {
    if (img) {
      const reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onloadend = () => {
        const dataUrl = reader.result;
        setUrl(dataUrl);
      };
    }
  }, [img]);
  const renderSwatches = () => colors?.map((color) => (
    <div
      className="colors-wrapper_item"
      key={color?.id}
      style={{
        backgroundColor: color,
      }}
    >
      <span className="colors-wrapper_item_detail">{color}</span>
    </div>
  ));
  const getColors = (items) => {
    // setColors(items);
    if (items[0]) {
      setValue('primaryColor', items[0]);
    }
    if (items?.length) {
      setValue('secondaryColor', items[items?.length - 1]);
    }
  };

  return (
    <StyledExtractColor>
      <ColorExtractor src={url} getColors={getColors} />
    </StyledExtractColor>
  );
};

ExtractColor.propTypes = {
  img: PropTypes.any,
  setValue: PropTypes.func.isRequired,
};

ExtractColor.defaultProps = {
  img: false,
};

export default ExtractColor;
