import React from 'react';
import Layout from '../components/Layout/index';

function NotFound() {
  return (
    <Layout>
      <h1>NotFound</h1>
    </Layout>
  );
}

export default NotFound;
