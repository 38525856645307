import styled from 'styled-components';
import colors from '../../utils/vars';

const StyleLayout = styled.div`
  display: flex;
  min-height: 100vh;

  .layout-content {
    padding: 35px 65px;
    background-color: ${colors.athens};
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .logo-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0 20px 20px 20px;
    .label {
      color: ${colors.thunder};
      font-family: 'Canaro';
      font-size: 1.4rem;
      font-weight: 700;
      position: relative;
    }
    
    .logo {
      margin-top: 10px;
      width: 200px;
      figure {
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
`;

export default StyleLayout;
