import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import axios from '../../libs/axios';
import colors from '../../utils/vars';
import FormError from '../FormQuizz/FormError';
import FormField from '../FormQuizz/FormField';
import FormInput from '../FormQuizz/FormInput';
import Upload from '../Upload';
import ExtractColor from '../ColorExtractor';

const StyledForm = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .color-pickers-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: -20px;
    margin-bottom: 20px;
    input {
      background-color: transparent;
    }
  }
  
  
  button {
   &[type=submit] {
     display: block;
     margin: 25px auto 0;
     padding: 10px;
     font-size: 1rem;
     text-align: center;
     color: ${colors.white};
     background-color: ${colors.blaze};
     border-radius: 5px;
     transition: background-color .2s;
     font-size: 1rem;
     font-weight: 700;
     text-transform: uppercase;

     &:hover {
       background-color: ${colors.crusta};
     }
   }
  }
`;
const FormClientCreate = ({ onClose, setReloadedDatas }) => {
  const {
    handleSubmit, register, formState: { errors }, watch, setValue,
  } = useForm({
    defaultValues: {
      primaryColor: colors.blaze,
      secondaryColor: colors.crusta,
    },
  });

  const onSubmit = async (values) => {
    const bodyData = { ...values };
    let notLogo = true;
    if (values?.logo && typeof values?.logo === 'object' && values.logo[0]) {
      const newName = new Date().getTime() + values.logo[0].name;
      notLogo = false;
      const formData = new FormData();
      formData.append('file', values.logo[0], newName);
      const postUploadResp = await axios.post('/upload', formData);
      _.set(bodyData, 'logo', postUploadResp?.data?.publicUrl);
    }

    if (notLogo) {
      bodyData.logo = null;
    }
    axios.post('/client', { ...bodyData })
      .then(() => {
        setReloadedDatas(true);
        onClose();
        setTimeout(() => {
          setReloadedDatas(false);
        }, 500);
      })
      .catch((err) => console.error(err));
  };

  const logo = watch('logo');

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Upload label="Logo" register={register} errors={errors} name="logo" />
      <div className="color-pickers-wrapper">
        <FormField
          id="primaryColor"
          label="Couleur principale"
          required
          width="auto"
        >
          <FormInput
            register={register('primaryColor')}
            classname="primaryColor"
            type="color"
          />
          <FormError
            error={_.get(errors, 'primaryColor')}
          />
        </FormField>

        <FormField
          id="secondaryColor"
          label="Couleur secondaire"
          required
          width="auto"
        >
          <FormInput
            register={register('secondaryColor')}
            classname="primaryColor"
            type="color"
          />
          <FormError
            error={_.get(errors, 'secondaryColor')}
          />
        </FormField>
      </div>
      <FormField
        id="name"
        label="Nom du client"
      >
        <FormInput
          register={register('name', {
            required: {
              value: true,
              message: 'Ce champs est requis',
            },
          })}
          type="text"
        />
        <FormError
          error={_.get(errors, 'name')}
        />
      </FormField>
      <FormField
        id="frontUrl"
        label="Url de l'application"
      >
        <FormInput
          register={register('frontUrl', {
            required: {
              value: false,
            },
          })}
          type="text"
        />
        <FormError
          error={_.get(errors, 'frontUrl')}
        />
      </FormField>

      <ExtractColor img={logo && typeof logo !== 'string' ? logo[0] : null} setValue={setValue} />

      <button
        type="submit"
        onClick={onSubmit}
      >
        Créer un client
      </button>
    </StyledForm>
  );
};

FormClientCreate.propTypes = {
  onClose: PropTypes.func.isRequired,
  setReloadedDatas: PropTypes.func.isRequired,
};

export default FormClientCreate;
