import React from 'react';
import { PuffLoader, BarLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import colors from '../../utils/vars';

const Spinner = ({ type }) => (
  <div style={{
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
  >
    {
          type === 'barLoader' ? (<BarLoader color={colors.blaze} size={100} />) : (<PuffLoader color={colors.blaze} size={100} />)
      }

  </div>
);

Spinner.propTypes = {
  type: PropTypes.string,
};

Spinner.defaultProps = {
  type: '',
};

export default Spinner;
