/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import colors from '../../../utils/vars';

const FormField = styled.div`
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
    width: ${(props) => (props?.width ? props.width : '100%')};
    text-align: left;

    .label {
        color: ${colors.thunder};
        font-family: 'Canaro';
        font-size: 1.4rem;
        font-weight: 700;
        position: relative;
    }

    & + & {
        margin-top: 30px;
    }
`;

export default FormField;
