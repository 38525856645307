import _ from 'lodash';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import io from 'socket.io-client';
import { ReactComponent as Trash } from '../../svgs/trash.svg';
import { ReactComponent as ChevRight } from '../../svgs/chevron-right.svg';
import { ReactComponent as ChevLeft } from '../../svgs/chevron-left.svg';
import { ReactComponent as QrCodeIcon } from '../../svgs/qrCode.svg';
import { ReactComponent as Cross } from '../../svgs/cross.svg';
import Layout from '../../components/Layout/index';
import StepDetail from '../../components/StepDetail/StepDetail';
import axios from '../../libs/axios';
import { startTimer } from '../../redux/questions/slice';
import StyledSessionDetail from './styledSessionDetail';

// const ENDPOINT = process.env.REACT_APP_BACK_URL || 'https://api.quizzv2.sevatest.fr';
const ENDPOINT = process.env.REACT_APP_BACK_URL || 'https://api.quizz.devel';

function SessionDetail() {
  const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf('/') + 1);
  const dispatch = useDispatch();

  const [session, setSession] = useState();
  const [stats, setStats] = useState({});
  const [score, setScore] = useState([]);
  const location = useLocation();
  const [socket, setSocket] = useState();
  const [start, setStart] = useState();
  const [scenarioIndex, setScenarioIndex] = useState();
  const [infosGroup, setInfosGroup] = useState();
  const [result, setResult] = useState(null);
  const [lockSocket, setLockSocket] = useState();
  const getSession = async () => {
    const sessionId = getLastItem(location?.pathname);
    const sessResp = await axios.get(`/session/${sessionId}`);
    const clientResp = await axios.get(`client/${sessResp?.data?.quizzId?.client?._id}`);
    setSession({ ...sessResp?.data, frontUrl: `${clientResp?.data?.frontUrl}/${sessionId}` });
    if (!socket) {
      setSocket(io(ENDPOINT));
    }
  };

  useEffect(() => {
    if (!lockSocket && session && socket) {
      setLockSocket(true);
      if (!session?.finished && session?.type === 'live') {
        socket.emit('reset', { room: session._id, quizz: session.quizzId });
        setScenarioIndex(null);
        setStart(false);
      }
    }
  }, [session, socket]);

  useEffect(() => {
    getSession();
  }, [location]);

  useEffect(() => {
    if (socket) {
      socket.emit('joinQuizzSession', session._id);
      socket.on('createUser', (data) => {
        setInfosGroup(data);
      });
      socket.on('stats', (data) => {
        setStats({ ...stats, [data.value.questionId]: data.value.responses });
        setScore(data.score);
      });
      socket.on('finish', (data) => {
        setResult(data);
        getSession();
      });
      socket.on('next', ({ step }) => {
        dispatch(
          startTimer({
            time: session?.quizzId?.scenario[step]?.time,
            question: session?.quizzId?.scenario[step]?.question,
          }),
        );
      });
      socket.on('restart', () => {
        setInfosGroup(null);
        setResult(null);
        setScore(null);
        setStart(null);
        setStats(null);
        setSession(null);
        getSession();
      });
      socket.on('refresh', (data) => {
        setInfosGroup(JSON.parse(data));
      });

      const refreshStats = () => setTimeout(() => {
        if (socket) {
          socket.emit('refresh', session._id);
          if (session?.finished) {
            socket.emit('finish', { room: session._id });
          }
        }
      }, 1000);
      refreshStats();
    }
  }, [socket]);

  const HandleLiveReset = () => {
    socket.emit('reset', { room: session._id, quizz: session.quizzId });
    setScenarioIndex(null);
    setStart(false);
  };

  const HandleLiveDone = () => {
    setScenarioIndex(null);
    setStart(false);
  };

  const HandleForceFinish = () => {
    socket.emit('finish', { room: session._id });
    setScenarioIndex(null);
    setStart(false);
  };

  const handleNextStep = () => {
    if (scenarioIndex < session.quizzId.scenario.length - 1) {
      socket.emit('next', { room: session._id, step: scenarioIndex + 1 });
      setScenarioIndex(scenarioIndex + 1);
    } else if (scenarioIndex === session.quizzId.scenario.length - 1) {
      socket.emit('finish', { room: session._id });
      setScenarioIndex(scenarioIndex + 1);
    } else {
      setStart(false);
    }
  };

  const startQuizz = () => {
    console.log('Start QUIZZ =>  NEXT TO FIRST STEP');
    socket.emit('next', { room: session._id, step: 0 });
    setStart(true);
    setScenarioIndex(0);
  };

  const clossQuizz = () => {
    alert('close quizz');
    console.log('Close QUIZZ =>  Finish');
  };

  return (
    <Layout
      subtitle={session && session.quizzId.name}
      title={session && session.name}
      sessionUrl={session && session.frontUrl}
      quizzId={session && session.quizzId._id}
      sessionId={session && session._id}
      sessionFinished={session && session.finished}
      sessionDetail
    >
      <StyledSessionDetail>
        {/* {session?.frontUrl ? ( */}
        {/*  <div className="qr-code-wrapper"> */}
        {/*    { */}
        {/*          session?.finished && (<span>Session terminée</span>) */}
        {/*    } */}
        {/*    <QRCode value={session.frontUrl} size={150} /> */}
        {/*  </div> */}
        {/* ) : ( */}
        {/*  <span>Qr code indisponible</span> */}
        {/* )} */}

        {
            session?.finished && (<div className="session-finish"><span>Cette session est terminée !!</span></div>)
        }

        {
            !session?.finished && session?.quizzId?.eventType === 'free' && (<div className="session-finish"><span>Le quizz est active</span></div>)
        }

        {
            !start && (
            <section className="meta-wrapper">
              {
                  !session?.finished && !result && (
                  <div className="meta-wrapper_text">
                    <span>
                      {`${infosGroup?.numberOfParticipants ?? 0} participant${infosGroup?.numberOfParticipants > 1 ? 's' : ''}`}
                    </span>
                  </div>
                  )
              }

              {
                !session?.finished && !result && (
                <a href={`/session/${session?._id}/QRCode`} target="_blank" rel="noopener noreferrer" className="manatee">
                  page Qr-Code
                  <QrCodeIcon />
                </a>
                )
              }
              {
                  !session?.finished && !result && session?.type === 'live' && (
                  <button type="button" className="blaze" onClick={startQuizz}>
                    Démarrer
                    <ChevRight />
                  </button>
                  )
              }

              {
                  !session?.finished && session?.type === 'free' && !result && (
                  <button type="button" className="blaze" onClick={HandleForceFinish}>
                    Fermer le Quizz
                    <Cross />
                  </button>
                  )
              }

              <button type="button" className="rose" onClick={HandleLiveReset}>
                Réinitialiser
                <Trash />
              </button>

            </section>
            )
        }
        {
            start && session && (
            <section className="meta-wrapper row-reverse">
              <div className="meta-wrapper_text">
                <span>{`( ${infosGroup?.numberOfParticipants ?? 0} ) Participants`}</span>
              </div>

              <a href={`/session/${session?._id}/QRCode`} target="_blank" rel="noopener noreferrer" className="manatee">
                page Qr-Code
                <QrCodeIcon />
              </a>

              <button type="button" className="rose" onClick={HandleLiveReset}>
                EXIT
                <ChevLeft />
              </button>
            </section>
            )
        }
        {
            start && session && (
            <section className="meta-wrapper row">
              {
                !result ? (
                  <button type="button" className="blaze" onClick={handleNextStep}>
                    Question suivante
                    <ChevRight />
                  </button>
                ) : (
                  <button type="button" className="blaze" onClick={HandleLiveDone}>
                    Terminer
                    <ChevRight />
                  </button>
                )
              }
            </section>
            )
        }

        {(start || result) && session && (
          <section>
            <StepDetail
              step={_.get(session, `quizzId.scenario[${scenarioIndex}]`, false)}
              result={result}
              scenario={session?.quizzId?.scenario}
              session={session._id}
              stats={stats}
              score={score}
              nextStep={handleNextStep}
              resetSession={HandleLiveReset}
              numberOfParticipants={infosGroup?.numberOfParticipants}
              scenarioIndex={scenarioIndex}
            />
          </section>
        )}

        {
            session?.type === 'free' && session && (
            <section className="free-steps-wrapper">
              {
                _.get(session, 'quizzId.scenario', []).map((item, index) => (
                  <div key={item?.id}>
                    <StepDetail
                      step={item}
                      result={result}
                      scenario={session?.quizzId?.scenario}
                      session={session._id}
                      stats={stats}
                      score={score}
                      nextStep={handleNextStep}
                      resetSession={HandleLiveReset}
                      numberOfParticipants={infosGroup?.numberOfParticipants}
                      scenarioIndex={index}
                      noResult
                    />
                  </div>
                ))
              }
            </section>
            )
        }

      </StyledSessionDetail>
    </Layout>
  );
}

export default SessionDetail;
