import styled from 'styled-components';
import colors from '../../utils/vars';

const StyledCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 15px;
  height: 15px;
  border: 2px solid ${colors.manatee};
  border-radius: 50%;
  font-weight: 700;
  margin-top: 20px;
  margin-right: 10px;

  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding: 11px;
    z-index: 5;
    border-top: 2px solid ${colors.blaze};
    animation: rotateMe 1s linear infinite;
  }
  
  &.danger {
    border-color: ${colors.rose};
    scale: 1.5;
    span {
      color: ${colors.rose};
    }
    &:after {
      border-color: ${colors.manatee};
    }
  }

  @keyframes rotateMe {
  0% {
    transform: rotate(0) ;
  }
  13% {
    transform: rotate(45deg) ;
  }
  25% {
    transform: rotate(90deg) ;
  }
  38% {
    transform: rotate(135deg) ;
  }
  50% {
    transform: rotate(180deg) ;
  }
  62% {
    transform: rotate(225deg) ;
  }
  75% {
    transform: rotate(270deg) ;
  }
  87% {
    transform: rotate(315deg) ;
  }
  100% {
    transform: rotate(360deg) ;
  }
}
`;

export default StyledCounter;
