import PropTypes from 'prop-types';
import React from 'react';
import StyledCounter from './styled';

const Counter = ({ time }) => (
  <StyledCounter
    className={`counter ${time < 10 ? 'danger' : ''}`}
  >
    <span>{ time < 0 ? 0 : time}</span>
  </StyledCounter>
);

Counter.propTypes = {
  time: PropTypes.number.isRequired,
};

export default Counter;
