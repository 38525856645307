/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Clone, StyledAsideElement } from './styled';

// eslint-disable-next-line no-unused-vars
const AsideElement = ({
  label, children, index, id, top,
}) => (
  <Draggable
    key={id}
    draggableId={id}
    index={index}
  >
    {(provided, snapshot) => (
      <>
        <StyledAsideElement
          top={top}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {children}
          <p>
            {label}
          </p>
        </StyledAsideElement>
        {snapshot.isDragging && (
          <Clone>
            {children}
            <p>
              {label}
            </p>
          </Clone>
        )}
      </>
    )}
  </Draggable>
);

AsideElement.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  top: PropTypes.number.isRequired,
};

export default AsideElement;
