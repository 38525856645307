import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import axios from '../../libs/axios';
import colors from '../../utils/vars';
import FormError from '../FormQuizz/FormError';
import FormField from '../FormQuizz/FormField';
import FormInput from '../FormQuizz/FormInput';
import StyledFormSelect from '../FormQuizz/FormSelect';

const StyledForm = styled.form`
  margin-top: 20px;

  button {
    display: block;
    margin: 25px auto 0;
    padding: 10px;
    font-size: 1rem;
    text-align: center;
    color: ${colors.white};
    background-color: ${colors.blaze};
    border-radius: 5px;
    transition: background-color .2s;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  
    &:hover {
      background-color: ${colors.crusta};
    }
  }
`;
const FormUserCreate = ({ onClose, setReloadedDatas }) => {
  const {
    handleSubmit, control, register, formState: { errors },
  } = useForm();
  const { client, roles } = JSON.parse(localStorage.getItem('user'));
  const [optionsClient, setOptionsClient] = useState([]);

  useEffect(() => {
    if (roles === 'SuperAdmin') {
      axios.get('client').then((data) => setOptionsClient(data?.data));
    }
  }, []);

  const onSubmit = (values) => {
    axios.post('/users', { ...values, client: roles === 'SuperAdmin' ? values.client : client })
      .then(() => {
        setReloadedDatas(true);
        onClose();
        setTimeout(() => {
          setReloadedDatas(false);
        }, 500);
      })
      .catch((err) => console.error(err));
  };

  const options = [
    { value: 'SuperAdmin', label: 'SuperAdmin' },
    { value: 'Admin', label: 'Admin' },
    { value: 'Editor', label: 'Editor' },
  ];

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FormField
        id="lastname"
        label="Nom"
      >
        <FormInput
          register={register('lastname', {
            required: {
              value: true,
              message: 'Ce champs est requis',
            },
          })}
          type="text"
        />
        <FormError
          error={_.get(errors, 'lastname')}
        />
      </FormField>
      <FormField
        id="firstname"
        label="Prenom"
      >
        <FormInput
          register={register('firstname', {
            required: {
              value: true,
              message: 'Ce champs est requis',
            },
          })}
          type="text"
        />
        <FormError
          error={_.get(errors, 'firstname')}
        />
      </FormField>
      <FormField
        id="email"
        label="E-mail"
      >
        <FormInput
          register={register('email', {
            required: {
              value: true,
              message: 'Ce champs est requis',
            },
          })}
          type="email"
        />
        <FormError
          error={_.get(errors, 'email')}
        />
      </FormField>
      <FormField
        id="roles"
        label="Role"
      >
        <StyledFormSelect
          name="roles"
          options={options}
          control={control}
          register={register('roles', {
            required: {
              value: true,
              message: 'Ce champs est requis',
            },
          })}
        />
        <FormError
          error={_.get(errors, 'roles')}
        />
      </FormField>

      {
        roles === 'SuperAdmin' ? (
          <FormField
            id="client"
            label="Client"
          >
            <StyledFormSelect
              name="client"
              options={optionsClient.map((c) => {
                const { _id, name } = c;
                return {
                  label: name,
                  value: _id,
                };
              })}
              control={control}
              register={register('client', {})}
            />
            <FormError
              error={_.get(errors, 'client')}
            />
          </FormField>
        ) : null
      }

      <button
        type="submit"
        onClick={onSubmit}
      >
        Créer un utilisateur
      </button>
    </StyledForm>
  );
};

FormUserCreate.propTypes = {
  onClose: PropTypes.func.isRequired,
  setReloadedDatas: PropTypes.func.isRequired,
};

export default FormUserCreate;
