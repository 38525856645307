/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray } from 'react-hook-form';
import onDragEnd from '../../../libs/onDragEnd';
import FormAside from '../FormAside';
import FormQuestionDraggable from '../FormQuestionDraggable';
import StyledQuizzForm from './styled';

const QuizzForm = ({
  control, setValue, register, errors,
}) => {
  const {
    fields, append, remove, move, insert,
  } = useFieldArray({
    control,
    name: 'scenario',
  });

  return (
    <StyledQuizzForm>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, move, insert)}
      >
        <FormAside
          index={fields.length}
        />
        <form>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                className="wrapper"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {
                fields.map((item, index) => (
                  <Draggable
                    key={item?._id}
                    draggableId={item?._id}
                    index={index}
                    type="scenario"
                  >
                    {(provided2) => (
                      <FormQuestionDraggable
                        name={`scenario.${index}`}
                        provided={provided2}
                        control={control}
                        index={index}
                        register={register}
                        append={append}
                        errors={errors}
                        remove={remove}
                        item={item}
                        setValue={setValue}
                      />
                    )}
                  </Draggable>
                ))
              }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </form>
      </DragDropContext>
    </StyledQuizzForm>
  );
};

QuizzForm.propTypes = {
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default QuizzForm;
