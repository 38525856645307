/* eslint-disable react/forbid-prop-types */
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import FormError from '../FormQuizz/FormError';
import FormField from '../FormQuizz/FormField';
import FormInput from '../FormQuizz/FormInput';
import Upload from '../Upload';
import ExtractColor from '../ColorExtractor';
import colors from '../../utils/vars';

const StyledForm = styled.div`
  display: flex;
  width: 100%;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    gap: 30px;
    .color-pickers-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }

  
  }
`;

const FormClient = ({
  register, errors, watch, setValue,
}) => {
  const logo = watch('logo');

  return (
    <StyledForm>
      <form>
        <Upload register={register} errors={errors} name="logo" />

        <div className="color-pickers-wrapper">
          <FormField
            id="primaryColor"
            label="Couleur principale"
            required
            width="auto"
          >
            <FormInput
              register={register('primaryColor')}
              classname="primaryColor"
              type="color"
            />
            <FormError
              error={_.get(errors, 'primaryColor')}
            />
          </FormField>

          <FormField
            id="secondaryColor"
            label="Couleur secondaire"
            required
            width="auto"
          >
            <FormInput
              register={register('secondaryColor')}
              classname="primaryColor"
              type="color"
            />
            <FormError
              error={_.get(errors, 'secondaryColor')}
            />
          </FormField>
        </div>

        <FormField
          id="name-client"
          label="Nom du client"
          required
        >
          <FormInput
            register={register('name')}
            classname="secondary"
            type="text"
          />
          <FormError
            error={_.get(errors, 'name')}
          />
        </FormField>

        <FormField
          id="frontUrl"
          label="Url pour l'application"
          required
        >
          <FormInput
            register={register('frontUrl')}
            classname="secondary"
            type="text"
          />
          <FormError
            error={_.get(errors, 'frontUrl')}
          />
        </FormField>

        <ExtractColor img={logo && typeof logo !== 'string' ? logo[0] : null} setValue={setValue} />

      </form>
    </StyledForm>
  );
};

FormClient.propTypes = {
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setValue: PropTypes.object.isRequired,
};

export default FormClient;
