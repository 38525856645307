import styled from 'styled-components';
import colors from '../../utils/vars';

const StyleCard = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  
  .item {
    display: flex;
    position: relative;
    flex: 0 1 30%;
    flex-direction: column;
    margin-bottom: 40px;
    background-color: ${colors.white};
    
    &__inner {
      display: block;
      padding: 20px 28px 50px;
      transition: transform .2s, box-shadow .2s;
      height: 100%;
      
      &:hover {
        transform: translate(0, 4px);
        box-shadow: 0 -4px ${colors.manatee30};
  
        span,
        h3 {
          color: ${colors.blaze};
        }
      }
    }
    &__title {
      padding: 10px 5px;
      text-align: center;
    }
    
    span {
      display: block;
      margin-bottom: 10px;
      transition: color .2s;
      color: ${colors.manatee};
      font-family: 'Merriweather', sans-serif;
      font-style: italic;
      font-size: 1.5rem;
      font-weight: 700;
    }
    
    h3 {
      display: block;
      transition: color .2s;
      color: ${colors.thunder};
      font-family: 'Canaro', sans-serif;
      font-size: 1.4rem;
      font-weight: 700;
      text-transform: capitalize;
      line-height: 1.6;
    }
    
    &__btn {
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      bottom: -9px;
      width: 46px;
      height: 46px;
      padding: 0;
      transition: background-color .2s;
      background-color: ${colors.blaze};
      
      svg {
        width: 15px;
        height: 15px;
        fill: ${colors.white};
        path {
          fill: ${colors.white};
        }
      }
      
      &.edit {
        right: 46px;
      } 
      
      &.link {
        right: 46px;
      }
      
      &.delete {
        right: -9px;
        background-color: ${colors.rose};
      }

      &.clone {
        right: 100px;
        background-color: ${colors.emperor};
      }

      &:hover {
        background-color: ${colors.crusta};
        
        &.delete {
          background-color: ${colors.blush};
        }
        &.clone {
          background-color: ${colors.manatee30};
        }
      }
    }
    
    figure {
      padding: 10px;
      width: 150px;
      height: 150px;
      margin: 0 auto;
     
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .video-wrapper {
      width: 80%;
      padding: 10px 0;
      height: 150px;
      margin: 0 auto;
    }
    video {
      height: 100%;
      width: 100%;
    }
  }
  
  &::after {
    content: '';
    flex: 0 1 30%;
  }
`;

export default StyleCard;
