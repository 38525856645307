// 60d48256e2474a002a8e6a95
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { ObjectID } from 'bson';
import ConfigurationQuizzForm from '../../components/FormQuizz/ConfigurationQuizzForm';
import Layout from '../../components/Layout/index';
import axios from '../../libs/axios';
import Spinner from '../../components/Spinner/Spinner';

const QuizzConfiguration = () => {
  const { id } = useParams();
  const [quizz, setQuizz] = useState();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user'));

  const [isLoading, setIsLoading] = useState();
  const {
    control, setValue, reset, register, handleSubmit, watch, getValues, formState: { errors },
  } = useForm({
    defaultValues: {
      repeatPrimaryBg: 'false',
      repeatSecondaryBg: 'false',
    },
  });

  useEffect(() => {
    if (id) {
      const getQuizz = async () => {
        const resp = await axios.get(`/quizz/${id}`);
        setQuizz({
          ...resp?.data,
          repeatPrimaryBg: resp?.data.repeatPrimaryBg?.toString(),
          repeatSecondaryBg: resp?.data.repeatSecondaryBg?.toString(),
        });
      };
      getQuizz();
    } else {
      setTimeout(() => {
        setValue('primaryColor', user?.client?.primaryColor);
        setValue('secondaryColor', user?.client?.secondaryColor);
        setValue('labelColor', user?.client?.primaryColor);
      }, 1);
    }
  }, [history]);

  const primaryBackground = watch('primaryBackground');
  const secondaryBackground = watch('secondaryBackground');

  const onSubmit = async (values) => {
    setIsLoading(true);
    const tmpValues = { ...values };
    if (!user?.roles?.includes('SuperAdmin')) {
      tmpValues.client = user?.client?._id;
    }
    let notPrimaryBackground = true;
    if (values?.primaryBackground && typeof values?.primaryBackground === 'object' && values.primaryBackground[0]) {
      const newName = new Date().getTime() + values.primaryBackground[0].name;
      notPrimaryBackground = false;
      const formData = new FormData();
      formData.append('file', values.primaryBackground[0], newName);

      const postUploadResp = await axios.post('/upload', formData);
      _.set(tmpValues, 'primaryBackground', postUploadResp?.data?.publicUrl);
    }
    tmpValues.repeatPrimaryBg = tmpValues.repeatPrimaryBg === 'true';
    tmpValues.repeatSecondaryBg = tmpValues.repeatSecondaryBg === 'true';

    if ((!tmpValues.primaryBackground && notPrimaryBackground) || (notPrimaryBackground && typeof values?.primaryBackground === 'object')) {
      tmpValues.primaryBackground = null;
    }

    let notScondaryBackground = true;
    if (values?.secondaryBackground && typeof values?.secondaryBackground === 'object' && values.secondaryBackground[0]) {
      const newName = new Date().getTime() + values.secondaryBackground[0].name;
      notScondaryBackground = false;
      const formData = new FormData();
      formData.append('file', values.secondaryBackground[0], newName);
      setValue('secondaryBackground', null);
      const postUploadResp = await axios.post('/upload', formData);
      _.set(tmpValues, 'secondaryBackground', postUploadResp?.data?.publicUrl);
    }

    if ((!tmpValues.secondaryBackground && notScondaryBackground) || (notScondaryBackground && typeof values?.secondaryBackground === 'object')) {
      tmpValues.secondaryBackground = null;
    }
    if (id) {
      axios.put(`/quizz/${id}`, { ...tmpValues })
        .then((res) => {
          toast.success('Configuration mise à jour');
          setQuizz(res?.data);
          setIsLoading(false);
        })
        .catch(() => toast.error('Erreur lors de la mise à jour'));
    } else {
      axios.post('/quizz', { ...tmpValues })
        .then((resp) => {
          toast.success('Créé avec succès !');
          history.push(`/quizz/${resp?.data?._id}/questions`);
        })
        .catch(() => toast.error('Erreur lors de la création'));
    }
  };

  useEffect(() => reset(quizz), [quizz]);

  return (
    <Layout
      title={quizz?.name}
      quizzId={quizz?._id}
      quizzPage
      handleSubmit={handleSubmit(onSubmit)}
    >
      { (id && !quizz) || isLoading ? (<Spinner />) : (
        <ConfigurationQuizzForm
          control={control}
          setValue={setValue}
          register={register}
          errors={errors}
          watch={watch}
          getValues={getValues}
        />
      )}

    </Layout>
  );
};

export default QuizzConfiguration;
