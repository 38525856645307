import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../utils/vars';

export const StyledDashboard = styled.section`
  display: flex;
  width: 100%;
  margin-right: auto;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  
  .banner {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: bounce-in 2s ease;
    @keyframes hithere {
      30% { transform: scale(1.2); }
      40%, 60% { transform: rotate(-20deg) scale(1.2); }
      50% { transform: rotate(20deg) scale(1.2); }
      70% { transform: rotate(0deg) scale(1.2); }
      100% { transform: scale(1); }
    }
    @keyframes bounce-in {
      0% {
        opacity: 0;
        transform: scale(.3);
      }
      50% {
        opacity: 1;
        transform: scale(1.05);
      }
      70% { transform: scale(.9); }
      100% { transform: scale(1); }
    }
  }
  
  
  h2 {
    font-size: 8rem;
    font-family: 'Canaro', sans-serif;
    text-transform: uppercase;
    color: ${colors.thunder};
    font-weight: 700;
    
    &.important  {
      color: ${colors.blaze};
    }
  }
`;

export const StyledLink = styled(Link)`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  color: ${colors.manatee};
  transition: color .2s;
  font-family: 'Canaro', sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: ${colors.white};
  transition: background-color .2s;
  border-radius: 25px;

  svg {
    width: 18px;
    height: 18px;
    margin: 0 20px 0 0;
    fill: ${colors.thunder};
  }
  
  &:hover {
    color: ${colors.thunder};
    background-color: ${colors.manatee30};
  }
`;
