import styled from 'styled-components';
import colors from '../../utils/vars';

const StyleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  margin-bottom: 40px;
  padding-bottom: 15px;
  border-bottom: 2px solid ${colors.manatee30};

  .title {

    animation: bounce-in-right 2s ease;
    
    @keyframes bounce-in-right {
      0% {
        opacity: 0;
        transform: translateX(2000px);
      }
      60% {
        opacity: 1;
        transform: translateX(-30px);
      }
      80% {
        transform: translateX(10px);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  span {
    font-family: 'Merriweather', sans-serif;
    font-style: italic;
  }

  h2 {
    max-width: 34ch;
    color: ${colors.manatee};
    font-family: 'Canaro', sans-serif;
    font-size: 2.7rem;
    font-weight: 800;
    line-height: 1.3;
    text-transform: uppercase;

    &.is-title {
      color: ${colors.thunder};
    }
  }
`;

export default StyleHeader;
