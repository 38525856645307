const colors = {
  white: '#fff',
  black: '#000',
  thunder: '#231f20',
  emperor: '#52484b',
  blaze: '#ff5c0f',
  crusta: '#ff7c3f',
  athens: '#f1eff2',
  manatee: '#9295a5',
  manatee30: 'rgba(146, 149, 165, .3)',
  rose: '#e5396c',
  blush: '#ea6089',
  minsk: '#3a2c77',
  scooter: '#26b8c8',
  whisper: '#eef0f6',
  wild: '#8588b2',
  rhino: '#32355e',
  pureApple: '#6ab04c',
  juneBud: '#badc58',
};

export default colors;
