import styled from 'styled-components';
import colors from '../../utils/vars';

const StyledSessionForm = styled.form`
  margin-top: 20px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-height: 500px;


  overflow-y: auto;
  
  button {
    display: block;
    margin: 25px auto 0;
    padding: 10px;
    font-size: 1rem;
    text-align: center;
    color: ${colors.white};
    background-color: ${colors.blaze};
    border-radius: 5px;
    transition: background-color .2s;
    font-weight: 700;
    text-transform: uppercase;
  
    &:hover {
      background-color: ${colors.crusta};
    }
  }
  
  .group-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    button {
      padding: 15px 0;
      width: 200px;
    }
  }
  
  .add-group-wrapper {
    display: flex;
    width: 100%;
    button {
      margin: 0;
    }
  }
  
`;

export default StyledSessionForm;
