import styled from 'styled-components';

const StyledStepDetail = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20;

  table {
    display: inline;
    border-collapse: collapse;
    td {
      padding: 10px;
      border: 1px solid white;

      strong {
        text-transform: uppercase;
      }
    }
  }
`;

export default StyledStepDetail;
