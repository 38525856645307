/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import { ObjectID } from 'bson';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';
import selectFormFieldDependType from '../../../libs/selectFormFieldDependType';
import { ReactComponent as Copy } from '../../../svgs/copy.svg';
import { ReactComponent as Pen } from '../../../svgs/pen.svg';
import { ReactComponent as Trash } from '../../../svgs/trash.svg';
import StyleFormField from './styled';

const FormDraggable = ({
  item, provided, control, name, register, remove, append, index, errors, setValue,
}) => {
  /** State */
  const [open, setOpen] = useState(false);

  /** Watcher */
  const questionWatch = useWatch({
    control,
    name: `${name}.question`,
  });

  /** Utils */
  const {
    mediaUrl, question, responses, time, type, _id,
  } = item;

  const duplicate = {
    _id: new ObjectID().toHexString(),
    question,
    responses: responses?.map((response) => ({ ...response, _id: new ObjectID().toHexString() })),
    time,
    type,
    mediaUrl,
  };

  const handleConfirm = () => {
    if (window.confirm(`Supprimer la question "${questionWatch}"`)) {
      remove(index);
    }
  };

  const FormFieldDependType = selectFormFieldDependType(type);

  return (
    <StyleFormField
      ref={provided.innerRef}
      {...provided.draggableProps}
      open={open}
      error={_.get(errors, name)}
    >
      <div
        className="header"
        onClick={() => setOpen(!open)}
      >
        <p
          className="drag"
          {...provided.dragHandleProps}
        />
        <p>
          {questionWatch}
        </p>
        <div className="cta">
          <button
            type="button"
            title="Supprimer"
            onClick={() => handleConfirm()}
          >
            <Trash />
          </button>
          <button
            type="button"
            title="Dupliquer"
            onClick={() => append(duplicate)}
          >
            <Copy />
          </button>
          <button
            type="button"
            title="Editer"
            onClick={() => setOpen(!open)}
          >
            <Pen />
          </button>
        </div>
      </div>
      <div
        className="content"
      >
        <FormFieldDependType
          type={type}
          name={name}
          setValue={setValue}
          question={question}
          responses={responses}
          time={time}
          _id={_id}
          errors={errors}
          control={control}
          register={register}
        />
      </div>
    </StyleFormField>
  );
};

FormDraggable.propTypes = {
  name: PropTypes.string,
  question: PropTypes.string,
  responses: PropTypes.arrayOf({
    _id: PropTypes.string,
    label: PropTypes.string,
    isGood: PropTypes.bool,
  }),
  time: PropTypes.number,
  type: PropTypes.string,
  _id: PropTypes.string,
  provided: PropTypes.objectOf({
    innerRef: PropTypes.func.isRequired,
    draggableProps: PropTypes.string.isRequired,
    dragHandleProps: PropTypes.object.isRequired,
  }).isRequired,
};

export default FormDraggable;
