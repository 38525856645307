import styled from 'styled-components';
import colors from '../../utils/vars';

const StyledUpload = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  label {
    color: ${colors.thunder};
    font-family: 'Canaro';
    font-size: 1.4rem;
    font-weight: 700;
    position: relative;
  }
`;

export default StyledUpload;
