import { Promise } from 'bluebird';
import _, { unset } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ObjectID } from 'bson';
import Card from '../../components/Card';
import Layout from '../../components/Layout/index';
import Spinner from '../../components/Spinner/Spinner';
import axios from '../../libs/axios';
import { ReactComponent as Pen } from '../../svgs/pen.svg';
import { ReactComponent as Trash } from '../../svgs/trash.svg';
import { ReactComponent as Clone } from '../../svgs/clone.svg';
import FormModal from '../../components/FormModal';

function QuizzDetail() {
  const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf('/') + 1);
  const [quizz, setQuizz] = useState();
  const [sessions, setSessions] = useState();
  const [updateState, setUpdateState] = useState(0);
  const [onRefreshDatas, setOnRefreshDatas] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [formType, setFormType] = useState(null);
  const [reloadedDatas, setReloadedDatas] = useState(false);
  const [selectedSession, setSelectedSession] = useState();

  useEffect(() => {
    const quizzId = id;
    Promise.props({
      getQuizz: axios.get(`/quizz/${quizzId}`),
      getSessions: axios.get(`/session?quizzId=${quizzId}`),
    }).then((res) => {
      setQuizz(_.get(res, 'getQuizz.data', {}));
      setSessions(_.get(res, 'getSessions.data', []));
    }).catch((err) => {
      console.log(err);
    });
  }, [location, onRefreshDatas, reloadedDatas]);

  useEffect(() => {
    if (!modalOpen) {
      setSelectedSession(null);
    }
  }, [modalOpen]);
  const deleteSession = (sessionId, position) => {
    axios.delete(`/session/${sessionId}`)
      .then(() => {
        const tmpSessions = sessions;
        tmpSessions.splice(position, 1);
        setSessions(tmpSessions);
        setUpdateState(updateState + 1);
        toast.success('supprimée avec succès !');
      }).catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => { }, [updateState]);

  const clonseSession = (targetSession) => {
    const tmpClonedSession = _.cloneDeep(targetSession);
    tmpClonedSession.finished = false;
    tmpClonedSession.stats = [];
    tmpClonedSession.name += ' - copie';
    unset(tmpClonedSession, '__v');
    unset(tmpClonedSession, '_id');
    axios.post('/session', { ...tmpClonedSession })
      .then((resp) => {
        toast.success('Clonée avec succès !');
        setReloadedDatas(!reloadedDatas);
      }).catch(() => toast.error('Erreur lors du clone'));
  };

  return (
    <Layout
      title={quizz && quizz.name}
      quizzId={quizz && quizz._id}
      quizzPage
      onRefreshDatas={setOnRefreshDatas}
    >
      { !sessions && <Spinner />}
      <Card>
        {sessions && sessions.length > 0 && sessions.map((item, itemIndex) => (
          <div key={item._id} className="item">
            <Link
              to={{
                pathname: `/session/${item._id}`,
              }}
              className="item__inner"
            >
              <span>{`Session ${itemIndex + 1}`}</span>

              <h3>{item.name}</h3>
            </Link>

            <button type="button" className="item__btn clone" onClick={() => window.confirm('Cloner cette session ?') && clonseSession(item)}>
              <Clone />
            </button>

            <button
              type="button"
              className="item__btn edit"
              onClick={() => {
                setModalOpen(!modalOpen);
                setFormType('session');
                setSelectedSession(item);
              }}
            >
              <Pen />
            </button>

            <button type="button" className="item__btn delete" onClick={() => window.confirm('Supprimer cette session') && deleteSession(item._id, itemIndex)}>
              <Trash />
            </button>
          </div>
        ))}
      </Card>
      <FormModal
        open={modalOpen}
        setModalOpen={setModalOpen}
        formType={formType}
        quizzId={quizz?._id}
        setReloadedDatas={setReloadedDatas}
        selectedItem={selectedSession}
      />
    </Layout>
  );
}

export default QuizzDetail;
