import styled from 'styled-components';
import colors from '../../../utils/vars';

const FormError = styled.span`
  position: absolute;
  left: 5px;
  bottom: -16px;
  width: 250px;
  font-size: 1.2rem;
  color: ${colors.blush};
`;

export default FormError;
