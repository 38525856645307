/* eslint-disable comma-dangle */
import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as Cross } from '../../svgs/cross.svg';
import { Container, Dialog, Overlay } from './styled';

const Context = React.createContext();

export function ModalProvider({ children }) {
  const modalRef = useRef();
  const [context, setContext] = useState();

  // make sure re-render is triggered after initial
  // render so that modalRef exists
  useEffect(() => {
    setContext(modalRef.current);
  }, []);

  return (
    <Container>
      {
            children && context && (
            <Context.Provider value={context}>{children}</Context.Provider>
            )
        }
      <div ref={modalRef} />
    </Container>
  );
}

// eslint-disable-next-line react/prop-types
export function Modal({ onClose, children, ...props }) {
  const modalNode = useContext(Context);

  return modalNode
    ? ReactDOM.createPortal(
      <Overlay>
        <Dialog {...props}>
          <button
            type="button"
            onClick={onClose}
          >
            <Cross />
          </button>
          {children}
        </Dialog>
      </Overlay>,
      modalNode,
    )
    : null;
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
