import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormUserEdit from '../../components/FormUserEdit';
import Layout from '../../components/Layout/index';
import axios from '../../libs/axios';

function ClientDetail() {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname?.match(/^\/me\/(\d+)/)) {
      axios.get(`/users/me/${id}`)
        .then((datas) => {
          setUser(datas.data);
        })
        .catch((err) => console.error(err));
    } else {
      axios.get(`/users/${id}`)
        .then((datas) => {
          setUser(datas.data);
        })
        .catch((err) => console.error(err));
    }
  }, []);

  const {
    control, setValue, reset, register, handleSubmit, formState: { errors },
  } = useForm({
    defaultValues: user,
  });

  const onSubmit = (values) => {
    if (location?.pathname?.match(/^\/me\/(\d+)/)) {
      axios.put(`/users/me/${id}`, values)
        .then((res) => {
          toast.success('Profil mis à jour');
          localStorage.setItem('user', JSON.stringify(res.data));
          setUser(values);
        })
        .catch(() => toast.error('Erreur lors de la mise à jour'));
    } else {
      axios.put(`/users/${id}`, values)
        .then(() => {
          toast.success('Utilisateur mis à jour');
          setUser(values);
        })
        .catch(() => toast.error('Erreur lors de la mise à jour'));
    }
  };

  useEffect(() => reset(user), [user]);
  console.log(location?.pathname?.match(/^\/me\/(\d+)/));
  return (
    <Layout
      title={user?.name}
      userId={user?._id}
      quizzPage={false}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <FormUserEdit
        control={control}
        setValue={setValue}
        register={register}
        errors={errors}
        profil={!!location?.pathname?.match(/^\/me\/(\d+)/)}
      />
    </Layout>
  );
}

export default ClientDetail;
