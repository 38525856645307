/* eslint-disable react/forbid-prop-types */
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import FormError from '../FormError';
import FormField from '../FormField';
import FormInput from '../FormInput';
import FormRadio from '../FormRadio';
import StyledQuizzForm from './styled';
import StyledFormSelect from '../FormSelect';
import FormUserEdit from '../../FormUserEdit';
import axios from '../../../libs/axios';
import Upload from '../../Upload';

const options = [
  { value: 'SuperAdmin', label: 'SuperAdmin' },
  { value: 'Admin', label: 'Admin' },
  { value: 'Editor', label: 'Editor' },
];
const QuizzForm = ({
  register, errors, control, watch, getValues, setValue,
}) => {
  const { client, roles } = JSON.parse(localStorage.getItem('user'));
  const [optionsClient, setOptionsClient] = useState([]);

  useEffect(() => {
    if (roles === 'SuperAdmin') {
      const getClient = async () => {
        const resp = await axios.get('client');
        setOptionsClient(resp?.data);
      };
      getClient();
    }
  }, []);

  const primaryBackground = watch('primaryBackground');
  const secondaryBackground = watch('secondaryBackground');

  const resetColors = () => {
    optionsClient?.forEach((item) => {
      if (item?._id === watch('client')) {
        setValue('primaryColor', item?.primaryColor);
        setValue('secondaryColor', item?.secondaryColor);
        setValue('labelColor', item?.primaryColor);
      }
    });
  };

  useEffect(() => {
    resetColors();
  }, [watch('client')]);

  return (
    <StyledQuizzForm>
      <form>
        <FormField
          id="name-quizz"
          label="Nom du quizz"
          required
        >
          <FormInput
            register={register('name')}
            classname="secondary"
            type="text"
          />
          <FormError
            error={_.get(errors, 'name')}
          />
        </FormField>

        {
          optionsClient && roles === 'SuperAdmin' ? (
            <FormField
              id="client"
              label="Client"
            >
              <StyledFormSelect
                name="client"
                options={optionsClient.map((c) => {
                  const { _id, name } = c;
                  return {
                    label: name,
                    value: _id,
                  };
                })}
                control={control}
                register={register('client', {})}
              />
              <FormError
                error={_.get(errors, 'client')}
              />
            </FormField>
          ) : null
        }

        <div className="color-pickers-wrapper">
          <FormField
            id="primaryColor"
            label="Couleur principale"
            required
            width="auto"
          >
            <FormInput
              register={register('primaryColor')}
              classname="primaryColor"
              type="color"
            />
            <FormError
              error={_.get(errors, 'primaryColor')}
            />
          </FormField>

          <FormField
            id="secondaryColor"
            label="Couleur secondaire"
            required
            width="auto"
          >
            <FormInput
              register={register('secondaryColor')}
              classname="secondaryColor"
              type="color"
            />
            <FormError
              error={_.get(errors, 'secondaryColor')}
            />
          </FormField>

          <FormField
            id="labelColor"
            label="Couleur des labels"
            required
            width="auto"
          >
            <FormInput
              register={register('labelColor')}
              classname="labelColor"
              type="color"
            />
            <FormError
              error={_.get(errors, 'labelColor')}
            />
          </FormField>

          <button type="button" onClick={() => resetColors()}>Réinitialiser les couleurs</button>
        </div>

        <Upload label="Background - Page d'authentification" register={register} errors={errors} name="primaryBackground" />
        {
            primaryBackground?.length > 0 && (
            <div className="bg-wrapper">
              <figure>
                <img src={primaryBackground} alt="primaryBackground" />
              </figure>
            </div>
            )
        }

        <FormField
          label="Répéter le background - Page d'authentification ?"
          required
        >
          <FormRadio>
            <label
              htmlFor={'bgPrimYes'}
            >
              Oui
              <input
                id={'bgPrimYes'}
                {...register('repeatPrimaryBg')}
                type="radio"
                value={'true'}
              />
              <span className="checkmark" />
            </label>
            <label
              htmlFor={'bgPrimNo'}
            >
              Non
              <input
                id={'bgPrimNo'}
                {...register('repeatPrimaryBg')}
                type="radio"
                value={'false'}
              />
              <span className="checkmark" />
            </label>
          </FormRadio>
          <FormError
            error={_.get(errors, 'repeatPrimaryBg')}
          />
        </FormField>

        <Upload label="Background - Questions" register={register} errors={errors} name="secondaryBackground" />

        {
            secondaryBackground?.length > 0 && (
            <div className="bg-wrapper">

              <figure>
                <img src={secondaryBackground} alt="secondaryBackground" />
              </figure>
            </div>
            )
        }

        <FormField
          label="Répéter le background - Questions ?"
          required
        >
          <FormRadio>
            <label
              htmlFor={'bgSecondYes'}
            >
              Oui
              <input
                id={'bgSecondYes'}
                {...register('repeatSecondaryBg')}
                type="radio"
                value={'true'}
              />
              <span className="checkmark" />
            </label>
            <label
              htmlFor={'bgSecondNo'}
            >
              Non
              <input
                id={'bgSecondNo'}
                {...register('repeatSecondaryBg')}
                type="radio"
                value={'false'}
              />
              <span className="checkmark" />
            </label>
          </FormRadio>
          <FormError
            error={_.get(errors, 'repeatSecondaryBg')}
          />
        </FormField>

        {/* <FormField */}
        {/*  id="defaultTimer" */}
        {/*  label="Timer par défaut (secondes)" */}
        {/*  required */}
        {/* > */}
        {/*  <FormInput */}
        {/*    register={register('defaultTimer', { */}
        {/*      minVal: 0, */}
        {/*    })} */}
        {/*    classname="secondary" */}
        {/*    type="number" */}
        {/*  /> */}
        {/*  <FormError */}
        {/*    error={_.get(errors, 'defaultTimer')} */}
        {/*  /> */}
        {/* </FormField> */}

        {/* <FormField */}
        {/*  id="scoring" */}
        {/*  label="Calcul des scores" */}
        {/*  required */}
        {/* > */}
        {/*  <FormRadio */}
        {/*    classname="secondary" */}
        {/*  > */}
        {/*    <label */}
        {/*      htmlFor="scoringResponseAndTime" */}
        {/*    > */}
        {/*      En live (Lancement du quiz contrôlé par un administrateur) */}
        {/*      <input */}
        {/*        id="scoringResponseAndTime" */}
        {/*        {...register('scoringType')} */}
        {/*        type="radio" */}
        {/*        value="responseAndTime" */}
        {/*      /> */}
        {/*      <span className="checkmark" /> */}
        {/*    </label> */}
        {/*    <label */}
        {/*      htmlFor="scoringResponse" */}
        {/*    > */}
        {/*      Accès libre (Les utilisateurs accèdent quand ils veulent au quiz) */}
        {/*      <input */}
        {/*        id="scoringResponse" */}
        {/*        {...register('scoringType')} */}
        {/*        type="radio" */}
        {/*        value="response" */}
        {/*      /> */}
        {/*      <span className="checkmark" /> */}
        {/*    </label> */}
        {/*  </FormRadio> */}
        {/*  <FormError */}
        {/*    error={_.get(errors, 'scoringType')} */}
        {/*  /> */}
        {/* </FormField> */}
      </form>
    </StyledQuizzForm>
  );
};

QuizzForm.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

QuizzForm.defaultProps = {};

export default QuizzForm;
