import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useLocation } from 'react-router-dom';
import QRCode from 'qrcode.react';
import Layout from '../../components/Layout/index';
import axios from '../../libs/axios';
import StyledSessionQrCode from './styledSessionQrCode';
import Spinner from '../../components/Spinner/Spinner';

function SessionQrCode() {
  const location = useLocation();
  const [session, setSession] = useState();
  const getLastItem = (thePath) => thePath.split('/')[2];

  const getSession = async () => {
    const sessionId = getLastItem(location?.pathname);
    const sessResp = await axios.get(`/session/${sessionId}`);
    const clientResp = await axios.get(`client/${sessResp?.data?.quizzId?.client?._id}`);
    setSession({ ...sessResp?.data, frontUrl: `${clientResp?.data?.frontUrl}/${sessionId}` });
  };

  useEffect(() => {
    getSession();
  }, [location]);

  return (
    <StyledSessionQrCode>
      <span>Veuillez scanner ce Qr-Code pour participer au quizz</span>
      {
            session ? (<QRCode value={session.frontUrl} size={300} />) : (<Spinner />)
        }
    </StyledSessionQrCode>
  );
}
export default SessionQrCode;
