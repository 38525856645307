/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentTimer, stopTimer } from '../../redux/questions/slice';
import { ReactComponent as Profil } from '../../svgs/profile.svg';
import Counter from '../Counter';
import {
  ProgressBar, Response, ResponseLabel, ResponseNumber, Responses, StyleOnBoard,
} from './style';

const OnBoardQuestion = ({
  stats, step, numberOfParticipants = 0, scenarioIndex, scenario,
}) => {
  const { timeLeft } = useSelector((state) => state.question);
  const dispatch = useDispatch();
  useEffect(() => (timeLeft <= 0 ? dispatch(stopTimer()) : null), [timeLeft]);
  const [totalNbResp, setTotalNbResp] = useState(-1);
  const [localStats, setLocalStats] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(currentTimer());
    }, 200);
    return () => clearInterval(interval);
  }, [step]);

  useEffect(() => {
    setTotalNbResp(0);
  }, [scenarioIndex]);

  useEffect(() => {
    if (stats && Object.keys(stats)?.includes(scenario[scenarioIndex]?._id)) {
      let tmpTotal = 0;
      Object.keys(stats[scenario[scenarioIndex]?._id]).forEach((key) => {
        tmpTotal += stats[scenario[scenarioIndex]?._id][key];
      });
      setTotalNbResp(tmpTotal);
      setLocalStats(stats);
    }
  }, [stats]);

  return (
    <StyleOnBoard>
      <h1>
        Question
        {' '}
        {parseInt(scenarioIndex, 10) + 1}
      </h1>
      <Counter time={timeLeft} />
      <span>{`Nombre de réponses : ${totalNbResp} / ${numberOfParticipants}`}</span>
      <Responses>
        <h2>{step?.question}</h2>
        {
          step.responses.map((response) => {
            const nbResponse = parseInt(_.get(localStats, [step?._id, response?._id], 0) ?? 0, 10);
            const percentResponse = ((nbResponse * 100)
              / (parseInt(totalNbResp, 10))).toFixed(0) ?? 0;
            return (
              <Response
                key={response?._id}
              >
                <ResponseLabel>
                  {response?.label}
                </ResponseLabel>
                <ProgressBar
                  width={percentResponse}
                />
                <ResponseNumber>
                  <p>
                    {`${percentResponse !== 'NaN' ? percentResponse : 0}%`}
                  </p>
                  <p
                    className="response-number"
                  >
                    <Profil />
                    {nbResponse}
                  </p>
                </ResponseNumber>
              </Response>
            );
          })
        }
      </Responses>
    </StyleOnBoard>
  );
};

export default OnBoardQuestion;
